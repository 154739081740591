<template>
  <div class="cbib">

    <md-steppers :md-active-step.sync="horizontalactive" md-horizontal md-linear>
      <md-step id="first" :md-label="currentgeneralstrings[9]" :md-editable="false" :md-done="true">

      </md-step>

      <md-step id="second" :md-label="currentgeneralstrings[10]" :md-editable="false" :md-done="true">

      </md-step>

      <md-step id="third" :md-label="currentgeneralstrings[11]" :md-editable="false" :md-done="true">

      </md-step>

      <md-step id="fourth" :md-label="currentgeneralstrings[12]">

      </md-step>

      <md-step id="fifth" :md-label="currentgeneralstrings[13]">

      </md-step>
    </md-steppers>

    <div id="questioncard" class="md-card md-theme-demo-light">
      <p class="section-title">{{ currentwording[0] }}
      </p>
      <p class="section-title">
        {{ currentwording[1] }}
      </p>
      <p class="section-title"> {{ currentwording[2] }}
      </p>

      <div>
        <md-steppers :md-active-step.sync="active" md-vertical md-linear>
          <md-step id="one" :md-label="currenttextset[0]" :md-error="StepOneError" :md-done.sync="one">
            <FormulateInput v-model="value1" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('one', 'two')">{{ currentgeneralstrings[0] }}
            </md-button>
          </md-step>

          <md-step id="two" :md-label="currenttextset[1]" :md-error="StepTwoError" :md-done.sync="two">
            <FormulateInput v-model="value2" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('two', 'one')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('two', 'three')">{{ currentgeneralstrings[0] }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="three" :md-label="currenttextset[2]" :md-error="StepThreeError" :md-done.sync="three">
            <FormulateInput v-model="value3" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('three', 'two')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('three', 'four')">{{ currentgeneralstrings[0] }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="four" :md-label="currenttextset[3]" :md-error="StepFourError" :md-done.sync="four">
            <FormulateInput v-model="value4" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('four', 'three')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('four', 'five')">{{ currentgeneralstrings[0] }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="five" :md-label="currenttextset[4]" :md-error="StepFiveError" :md-done.sync="five">
            <FormulateInput v-model="value5" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('five', 'four')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('five', 'six')">{{ currentgeneralstrings[0] }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="six" :md-label="currenttextset[5]" :md-error="StepSixError" :md-done.sync="six">
            <FormulateInput v-model="value6" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('six', 'five')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('six', 'seven')">{{ currentgeneralstrings[0] }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="seven" :md-label="currenttextset[6]" :md-error="StepSevenError" :md-done.sync="seven">
            <FormulateInput v-model="value7" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('seven', 'six')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('seven', 'eight')">{{ currentgeneralstrings[0] }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="eight" :md-label="currenttextset[7]" :md-error="StepEightError" :md-done.sync="eight">
            <FormulateInput v-model="value8" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('eight', 'seven')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('eight', 'nine')">{{ currentgeneralstrings[0] }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="nine" :md-label="currenttextset[8]" :md-error="StepNineError" :md-done.sync="nine">
            <FormulateInput v-model="value9" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('nine', 'eight')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('nine', 'ten')">{{ currentgeneralstrings[0] }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="ten" :md-label="currenttextset[9]" :md-error="StepTenError" :md-done.sync="ten">
            <FormulateInput v-model="value10" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('ten', 'nine')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('ten', 'eleven')">{{ currentgeneralstrings[0] }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="eleven" :md-label="currenttextset[10]" :md-error="StepElevenError" :md-done.sync="eleven">
            <FormulateInput v-model="value11" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('eleven', 'ten')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('eleven', 'twelve')">{{ currentgeneralstrings[0] }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="twelve" :md-label="currenttextset[11]" :md-error="StepTwelveError" :md-done.sync="twelve">
            <FormulateInput v-model="value12" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('twelve', 'eleven')">{{ currentgeneralstrings[1] }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setDone('third')">Done</md-button>-->
          </md-step>
        </md-steppers>
      </div>

      <div class="action-container">


        <FormulateInput id="saveforlater" v-on:click="saveForLater" type="submit" :label="currentgeneralstrings[4]" />


        <FormulateInput id="submit" v-on:click="setError" type="submit" :label="currentgeneralstrings[3]" />

      </div>





      <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration"
        :md-active.sync="showSnackbar" md-persistent>
        <span>{{ snackbarText }}</span>
        <md-button class="md-primary" @click="showSnackbar = false">OK</md-button>
      </md-snackbar>

      <md-dialog-confirm :md-active.sync="showAlert" :md-title="currentgeneralstrings[6]"
        :md-content="currentgeneralstrings[7]" :md-confirm-text="currentgeneralstrings[3]"
        :md-cancel-text="currentgeneralstrings[5]" @md-cancel="showAlert = false" @md-confirm="answerSubmit">
      </md-dialog-confirm>


      <div class="spinner">
        <bounce v-if="isLoading"></bounce>
      </div>

    </div>


  </div>
</template>
<script>
/*@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');*/
import { userstore } from '../data/userstore.js';

export default {
  name: 'StepperNonEditable',
  data() {
    return {
      datasent: 0,
      result: 0,
      casm: 0,
      mi: 0,
      ma: 0,
      pimc: 0,
      //     answers:{},
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      value5: "",
      value6: "",
      value7: "",
      value8: "",
      value9: "",
      value10: "",
      value11: "",
      value12: "",
      publicPath: process.env.BASE_URL,
      active: 'one',
      horizontalactive: 'fourth',
      one: true,
      two: true,
      three: true,
      four: true,
      five: true,
      six: true,
      seven: true,
      eight: true,
      nine: true,
      ten: true,
      eleven: true,
      twelve: true,
      StepOneError: null,
      StepTwoError: null,
      StepThreeError: null,
      StepFourError: null,
      StepFiveError: null,
      StepSixError: null,
      StepSevenError: null,
      StepEightError: null,
      StepNineError: null,
      StepTenError: null,
      StepElevenError: null,
      StepTwelveError: null,
      showSnackbar: false,
      position: 'center',
      duration: 4000,
      isInfinity: false,
      snackbarText: 'Submission error',
      showAlert: false,
      study_id: userstore.state.firstName,
      isdraft: 0,
      isLoading: false,
      lang: userstore.state.lang,
      wordingen: ["Please complete this questionnaire to help us to know how confident you are that you can do the following things. Be sure your ratings are about your confidence even if you have not done it in the past. So, your ratings are about your confidence that you can do these things now or in the near future.",
        "Please select the number between 1 and 9 that best applies to you, where 1 means “Not at all confident” and 9 means “Totally confident”. Numbers in the middle mean that you are somewhat confident that you can do that behavior.",
        "Please rate all items. If you are not sure about an item please rate it as best you can."],
      wordingit: ["Il presente questionario contiene una serie di cose che una persona potrebbe fare quando è in cura per il cancro. Noi siamo interessati a capire quanto lei pensa di essere in grado di attuarle. Si assicuri che le risposte riflettano accuratamente la sua fiducia, a prescindere o meno che lei abbia fatto queste cose in passato. In questo modo le sue risposte indicheranno la sua fiducia rispetto la possibilità di fare queste cose adesso o nel prossimo futuro.",
        "La preghiamo di leggere ogni frase e di indicare per ciascuna quanto lei si sente fiducioso di poter realizzare quel comportamento. Selezioni il numero sulla scala proposta: se lei seleziona il numero 1, lei sta dicendo che non si sente affatto fiducioso di poter realizzare quel comportamento; selezionando il numero 9, lei ritiene di essere assolutamente fiducioso di poter fare quella cosa. I numeri intermedi della scala indicano che lei si sente moderatamente fiducioso di realizzare quel comportamento.",
        "La preghiamo di rispondere a tutte le seguenti voci; qualora fosse incerto rispetto a qualcuna di esse, risponda nel modo migliore che può."],
      questiontexten: [
        "Question 1: Maintaining independence",
        "Question 2: Maintaining a positive attitude",
        "Question 3: Maintaining a sense of humor",
        "Question 4: Expressing negative feelings about cancer",
        "Question 5: Maintaining activities (work, home, hobbies, social)",
        "Question 6: Remaining relaxed throughout treatments and not allowing scary thoughts to upset me",
        "Question 7: Actively participating in treatment decisions",
        "Question 8: Asking physicians questions",
        "Question 9: Seeking consolation (support)",
        "Question 10: Sharing feelings of concern",
        "Question 11: Managing nausea and vomiting",
        "Question 12: Coping with physical changes"],
      questiontextit: [
        "Domanda 1: Mantenere l’indipendenza",
        "Domanda 2: Mantenere un atteggiamento positivo",
        "Domanda 3: Mantenere il senso dell’umorismo",
        "Domanda 4: Esprimere sentimenti sul cancro",
        "Domanda 5: Mantenere le attività (lavoro, casa, interessi, relazioni sociali)",
        "Domanda 6: Cercare di rimanere rilassato durante il trattamento e non farmi turbare da pensieri spaventosi",
        "Domanda 7: Partecipare attivamente alle decisioni sui trattamenti",
        "Domanda 8: Fare domande ai medici",
        "Domanda 9: Cercare supporto sociale",
        "Domanda 10: Condividere le mie preoccupazioni con gli altri",
        "Domanda 11: Gestire la nausea e il vomito (a prescindere che io abbia avuto questi problemi in passato)",
        "Domanda 12: Affrontare i cambiamenti fisici"],
      questionoptionsen: [
        { 1: '1= Not at all confident', 2: '2', 3: '3', 4: '4', 5: '5= Moderately confident', 6: '6', 7: '7', 8: '8', 9: '9= Totally confident' },
      ],
      questionoptionsit: [
        { 1: '1= Per niente fiducioso/a', 2: '2', 3: '3', 4: '4', 5: '5= Moderatamente fiducioso/a', 6: '6', 7: '7', 8: '8', 9: '9= Totalmente fiducioso/a' },
      ],
      generalstringsen: [
        "Next question",
        "Previous question",
        "Please answer question ",
        "Submit",
        "Save for later",
        "Cancel",
        "Alert",
        "Please note that some questions have not been answered. Press 'Submit' to skip these questions or 'Cancel' to continue answering.",
        "Please evaluate your overall health",
        "Quality of Life",
        "Head & Neck Cancer",
        "Health Questionnaire",
        "Cancer Behaviour Inventory",
        "Care received",
      ],
      generalstringsit: [
        "Prossima domanda",
        "Domanda precedente",
        "Per favore rispondi alla domanda",
        "Invia",
        "Salva per dopo",
        "Annulla",
        "Notifica",
        'Attenzione, non hai risposto ad alcune domande. Premi "Invia" per saltare queste domande oppure "Annulla" per continuare a rispondere.',
        "Per favore, valuta la tua salute",
        "EORTC QLQ-C30",
        "EORTC QLQ-H&N43",
        "EQ-5D-5L",
        "CBI-B",
        "PREM",
      ],
      currentoptionset: this.questionoptionsen,
      currenttextset: this.questiontexten,
      currentgeneralstrings: this.generalstringsen,
      currentwording: this.wordingen,
    }
  },
  methods: {
    setDone(id, index) {
      this[id] = true

      //  this.secondStepError = null

      if (index) {
        this.active = index
      }
    },
    saveForLater() {
      //save for later api call
      this.isdraft = 1;
      this.postAnswers();
      //  this.$router.push("/select-questionnaire");
    },
    setError() {
      if (this.value1 == "") {
        this.StepOneError = this.currentgeneralstrings[2] + "1";
      }
      if (this.value2 == "") {
        this.StepTwoError = this.currentgeneralstrings[2] + "2";
      }
      if (this.value3 == "") {
        this.StepThreeError = this.currentgeneralstrings[2] + "3";
      }
      if (this.value4 == "") {
        this.StepFourError = this.currentgeneralstrings[2] + "4";
      }
      if (this.value5 == "") {
        this.StepFiveError = this.currentgeneralstrings[2] + "5";
      }
      if (this.value6 == "") {
        this.StepSixError = this.currentgeneralstrings[2] + "6";
      }
      if (this.value7 == "") {
        this.StepSevenError = this.currentgeneralstrings[2] + "7";
      }
      if (this.value8 == "") {
        this.StepEightError = this.currentgeneralstrings[2] + "8";
      }
      if (this.value9 == "") {
        this.StepNineError = this.currentgeneralstrings[2] + "9";
      }
      if (this.value10 == "") {
        this.StepTenError = this.currentgeneralstrings[2] + "10";
      }
      if (this.value11 == "") {
        this.StepElevenError = this.currentgeneralstrings[2] + "11";
      }
      if (this.value12 == "") {
        this.StepTwelveError = this.currentgeneralstrings[2] + "12";
      }
      if (this.value1 == "" || this.value2 == "" || this.value3 == "" || this.value4 == "" || this.value5 == "" || this.value6 == "" || this.value7 == "" || this.value8 == "" || this.value9 == "" ||
        this.value10 == "" || this.value11 == "" || this.value12 == "") {
        this.showAlert = true;
        this.showSnackbar = false;
      }
      else {

        this.postAnswers();
        // this.$router.push("/care-received?alert="+"result: success");

      }
    },
    answerSubmit() {
      this.showAlert = false;

      this.postAnswers();
      //  this.$router.push("/care-received?alert="+"result: success");

    },
    currentDateGreek() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      today = yyyy + '-' + mm + '-' + dd;
      return today;
    },
    postAnswers() {
      this.isLoading = true;
      //  this.result=(Number(this.value1)+Number(this.value2)+Number(this.value3)+Number(this.value4)+Number(this.value5)+Number(this.value6)
      //   +Number(this.value7)+Number(this.value8)+Number(this.value9)+Number(this.value10)+Number(this.value11)+Number(this.value12));
      var questionable = [];

      if (this.value1 == "") {
        this.value1 = 0;
      }
      if (this.value2 == "") {
        this.value2 = 0;
      }
      if (this.value3 == "") {
        this.value3 = 0;
      }
      if (this.value4 == "") {
        this.value4 = 0;
      }
      if (this.value5 == "") {
        this.value5 = 0;
      }
      if (this.value6 == "") {
        this.value6 = 0;
      }
      if (this.value7 == "") {
        this.value7 = 0;
      }
      if (this.value8 == "") {
        this.value8 = 0;
      }
      if (this.value9 == "") {
        this.value9 = 0;
      }
      if (this.value10 == "") {
        this.value10 = 0;
      }
      if (this.value11 == "") {
        this.value11 = 0;
      }
      if (this.value12 == "") {
        this.value12 = 0;
      }

      this.casm = Number(this.value1) + Number(this.value2) + Number(this.value3);
      this.mi = Number(this.value5) + Number(this.value6) + Number(this.value11) + Number(this.value12);
      this.ma = Number(this.value4) + Number(this.value9) + Number(this.value10);
      this.pimc = Number(this.value7) + Number(this.value8);

      console.log('casm:' + this.casm);
      console.log('mi:' + this.mi);
      console.log('ma:' + this.ma);
      console.log('pimc:' + this.pimc);


      if (this.datasent == 0) {
        this.datasent = 1;
        var answers = [
          {
            "question_id": 79,
            "answer_integer": this.value1,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 80,
            "answer_integer": this.value2,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 81,
            "answer_integer": this.value3,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 82,
            "answer_integer": this.value4,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 83,
            "answer_integer": this.value5,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 84,
            "answer_integer": this.value6,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 85,
            "answer_integer": this.value7,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 86,
            "answer_integer": this.value8,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 87,
            "answer_integer": this.value9,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 88,
            "answer_integer": this.value10,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 89,
            "answer_integer": this.value11,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 90,
            "answer_integer": this.value12,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          }
        ];

        questionable = userstore.state.currentQuestionnaires.filter((q) => {
          return q.questionable_type === 'cbibs';
        });
        console.log("questionable - " + questionable[0].questionable_id);
        if (this.isdraft == 0) {
          this.$http.post('https://' + userstore.state.backurl + '/api/questionnaires/cbib', {
            "datetime": userstore.state.submissionDate,
            "answers": answers,
            "study_id": userstore.state.firstName,
            "casm": this.casm,
            "mi": this.mi,
            "me": this.ma,
            "pimc": this.pimc,
            "draft": this.isdraft,
            "questionable_id": questionable[0].questionable_id,
            //    "score":this.result,
            headers: {}
          })
            .then(function (response) {
              console.log(response);
              this.$router.push("/care-received");

            }).catch(function (response) {
              if (response.status == 404 || response.status == 403) {
                console.log(response);
              }
              else {  //for testing while wating for api
                this.$router.push("/care-received");
              }
            });
        }
        else {
          console.log(answers);
          this.$http.post('https://' + userstore.state.backurl + '/api/questionnaires/cbib', {
            "datetime": userstore.state.submissionDate,
            "answers": answers,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft,
            "questionable_id": questionable[0].questionable_id,
            //    "score":this.result,
            headers: {}
          })
            .then(function (response) {
              console.log(response);
              userstore.commit('changeId', null);
              // this.$router.push("/login");
              if (userstore.state.isClinician) {
                window.location.href = "/list-questionnaires";
              }
              else {
                window.location.href = "/login";
              }
            }).catch(function (response) {
              if (response.status != 200) {
                console.log(response);
              }
            });
        }
      }
    }
  },
  created: function () {
    window.scrollTo(0, 0);
    console.log(userstore.state.draftanswers);

    if (userstore.state.lang == "en") {
      this.currentoptionset = this.questionoptionsen;
      this.currenttextset = this.questiontexten;
      this.currentgeneralstrings = this.generalstringsen;
      this.currentwording = this.wordingen;
    }
    else if (userstore.state.lang == "it") {
      this.currentoptionset = this.questionoptionsit;
      this.currenttextset = this.questiontextit;
      this.currentgeneralstrings = this.generalstringsit;
      this.currentwording = this.wordingit;
    }


    for (var i = 0; i < userstore.state.draftanswers.length; i++) {
      if (userstore.state.draftquestionaire == "cbibs") {
        if (userstore.state.draftanswers[i].question_id == 79 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value1 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 80 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value2 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 81 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value3 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 82 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value4 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 83 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value5 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 84 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value6 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 85 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value7 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 86 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value8 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 87 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value9 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 88 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value10 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 89 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value11 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 90 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value12 = userstore.state.draftanswers[i].answer_integer;
        }
      }
    }

    if (this.$route.query.alert) {
      this.snackbarText = this.$route.query.alert;
      //                this.showSnackbar=true;
    }
  },

}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
