<template>
  <div class="hello">
    <table style="margin-left:auto;margin-right:auto;">
      <tr>
        <th>Participant ID</th>
        <th>Questionnaire</th>
        <th>Visit timing</th>
        <th>Status</th>
        <th>Action</th>
      </tr>
      <tr v-for="item in items" :key="item.id" class="md-card md-theme-demo-light dbcard">
        <td>{{ item.study_id }}</td>
        <td>{{ item.questionnaire_identity }}</td>
        <td>{{ item.visit_timing }}</td>
        <td>{{ item.questionnaire_status }}</td>
        <td>
          <md-button class="questionoption md-raised md-primary" v-if="lang == 'en'"
            @click="startQuestionnaire(item.study_id, item.visit_timing, item.questionable_id, item.start_questionaire_link)">
            Start Questionnaire</md-button>
          <md-button class="questionoption md-raised md-primary" v-if="lang == 'it'"
            @click="startQuestionnaire(item.study_id, item.visit_timing, item.questionable_id, item.start_questionaire_link)">
            Inizia il questionario</md-button>
        </td>
      </tr>
    </table>
    <div class="footer">
      <img id="eufundlogo" src="@/assets/eu-fund-logo.png" />
    </div>
    <div>

    <md-dialog :md-active.sync="active" v-if="lang == 'en'">
      <md-dialog-title>Select the date of the questionnaires' submission</md-dialog-title>

      <md-datepicker v-model="selectedDate">
        <label>Select date</label>
      </md-datepicker>
      <md-dialog-actions>
        <md-button class="md-primary" @click="redirect(questionnaire_link)">Done</md-button>
      </md-dialog-actions>
    </md-dialog>
    <md-dialog :md-active.sync="active" v-if="lang == 'it'">
      <md-dialog-title>Seleziona la data di presentazione dei questionari</md-dialog-title>

      <md-datepicker v-model="selectedDate">
        <label>Seleziona la data</label>
      </md-datepicker>
      <md-dialog-actions>
        <md-button class="md-primary" @click="redirect(questionnaire_link)">Fatto</md-button>
      </md-dialog-actions>
    </md-dialog>
    </div>
  </div>
</template>

<script>
/*@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');*/
import { userstore } from '../data/userstore.js';

export default {
  name: 'expose',
  data() {
    return {
      items: [
      ],
      active: false,
      selectedDate: null,
      questionnaire_link: '',
      lang: userstore.state.lang,
    }
  },
  methods: {
    async getDatabase() {
      // this.items = [{
      //   study_id: '20-1',
      //   visit_timing: 0
      // },
      // {
      //   study_id: '20-3',
      //   visit_timing: 6
      // }];
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      //urlencoded.append("username", userstore.state.firstName);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      await fetch(
        "https://" + userstore.state.backurl + "/api/patient/last/drafts/all",
        requestOptions
      )
        .then(response => { return response.json(); })
        .then((response) => {
          console.log(response.questionnaires);
          // for (var i = 0; i < response.questionnaires.length; i++) {
          //   //console.log(response.questionnaires[i].id);
          //   this.items.push(response.questionnaires[i]);
          // }
          Object.values(response.questionnaires).forEach((value) => {
            Object.values(value).forEach((value2) => {
              var draftquestionnaire = value2.questionable_type;
              console.log(draftquestionnaire);
              userstore.commit("changeDraftQuestionnaire", draftquestionnaire);
              if (draftquestionnaire == "quality_of_lives") {
                value2.start_questionaire_link = "/qlq-c30";
              }
              if (draftquestionnaire == "head_neck_cancers") {
                value2.start_questionaire_link = "/head-and-neck-cancer";
              }
              if (draftquestionnaire == "healths") {
                value2.start_questionaire_link = "/health";
              }
              if (draftquestionnaire == "cbibs") {
                value2.start_questionaire_link = "/cancer-behavior-inventory";
              }
              if (draftquestionnaire == "care_received") {
                value2.start_questionaire_link = "/care-received";
              }
              this.items.push(value2);
            })
          });
          //this.items = response.questionnaires;
        })
        .catch((error) => console.log("error", error));
    },
    async startQuestionnaire(study_id, visit_timing, questionable_id, start_questionaire_link) {
      this.active = true;
      userstore.commit('changefirstName', study_id);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("username", study_id);
      urlencoded.append("visit_timing", visit_timing);
      urlencoded.append("questionable_id", questionable_id);

      console.log(study_id);
      console.log(visit_timing);
      console.log(questionable_id);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      let jsonResponse = await fetch(
        "https://" + userstore.state.backurl + "/api/patient/draft",
        requestOptions
      )
        .then(response => {
          return response.json();
          //window.location.pathname = start_questionaire_link;
        })
        .catch((error) => console.log("error", error));

      userstore.commit("changeDraftAnswers", jsonResponse.answers);
      userstore.commit("changeCurrentQuestionnaires", jsonResponse.questionnaires);
      this.questionnaire_link = start_questionaire_link;
      // this.redirect(start_questionaire_link);
    },
    redirect(start_questionaire_link) {
      userstore.commit("changeSubmissionDate", new Date(`${this.selectedDate} UTC`));
      console.log(userstore.state.draftanswers);
      console.log(userstore.state.currentQuestionnaires);
      console.log(this.selectedDate);
      this.$router.push(start_questionaire_link);
    },
  },
  created: function () {
    this.getDatabase();
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

#backbutton {
  display: none;
}

.error_message {
  color: red;
  display: none;
}

.disabled {
  pointer-events: none;
}

.disabled button {
  background: #808080 !important;
}
</style>

