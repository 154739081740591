<template>
  <div class="hello">
    <div id="homecard"  class="md-card md-theme-demo-light">
        <img  id="bd4qicon" src="@/assets/bd4qol-logo.jpg"/>
        <div class="lang-menu">
          <div v-if="lang=='en'" class="selected-lang en">
              English
          </div>
          <div v-if="lang=='it'" class="selected-lang it">
              Italiano
          </div>
          <ul>
              <li>
                  <span  @click="changeToEn()" class="en">English</span>
              </li>
              <li>
                  <span  @click="changeToIt()" class="it">Italiano</span>
              </li>
          </ul>
          
        </div>
        <div class="loginmiddiv">
        <p v-if="lang=='en'">Please fill the following form to report your issue</p>
        <p v-if="lang=='it'">Please fill the following form to report your issue</p>

        <md-field @input="ToolChangeHandler" class="set-width">
          <label for="Tool"> Choose tool</label>
          <md-select @input="ToolChangeHandler" required v-model="tool" name="tool" id="report-tool">
            <md-option value="mobile_app">Mobile app</md-option>
            <md-option value="questionnaires_web">Questionnaires web</md-option>
          </md-select>
        </md-field>

        <md-field class="set-width">
          <label for="Category">Choose category</label>
          <md-select v-if="tool=='mobile_app'" required v-model="category" name="category" id="report-category">
         <md-option v-for="mobile_app_option in mobile_app_options" v-bind:key="mobile_app_option" v-bind:value="mobile_app_option"  >
            {{  mobile_app_option }}
          </md-option>
          </md-select>
        <md-select v-if="tool=='questionnaires_web'" required v-model="category" name="category" id="report-category">
         <md-option v-for="questionnaire_option in questionnaire_options" v-bind:key="questionnaire_option" v-bind:value="questionnaire_option"  >
            {{  questionnaire_option }}
          </md-option>
          </md-select>
        </md-field>


        </div>
        <div class="loginmiddiv report-text" >
            <md-field >
            <label v-if="lang=='en'">Describe your problem...</label>
            <label v-if="lang=='it'">Describe your problem...</label>

                <md-textarea  v-model="report_text" required></md-textarea>
            </md-field>
            <span style="color:red !important;" v-if="reporthasError" >Please, fill all fields to complete the report</span>
            

        </div>
        <div class="loginmiddiv">
        <md-button v-if="lang=='en'" @click="Validation()"  class="loginoption  md-raised md-primary">Report a problem</md-button>
        <md-button v-if="lang=='it'" @click="Validation()"  class="loginoption  md-raised md-primary">Invia a problem</md-button>
        
        </div>

        <div class="loginmiddiv">
        <a href="/select-questionnaire"><md-button  class="loginoption  md-raised md-primary">Main menu</md-button></a>
        </div>

          <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar" md-persistent>
        <div>{{ snackbarText }}</div>
        <div>
          <md-button class="md-primary" @click="showSnackbar = false">OK</md-button>
        </div>
      </md-snackbar>
     </div>
  </div>


</template>

<script>
import {userstore} from '../data/userstore.js';



    export default {
    name: 'add',
    data: () => ({
      showSnackbar:false,
      position: 'center',
      duration: 4000,
      isInfinity: true,
      snackbarText:'',
      noError: null,
      reporthasError: false,
      lang:userstore.state.lang,
      tool:null,
      category: null,
      report_text: null,
      mobile_app_options:['Steps','Phone','Alerts','Other'],
      questionnaire_options:['CBIB','QLQ','Health','Head and Neck','Care','Other'],
    }),
    methods: {
        ToolChangeHandler(){
          console.log('works');
          this.category=null;
        },
        Validation(){
            var urlencoded = new URLSearchParams();
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var current_date=new Date();
            var current_month = current_date.getUTCMonth();
            if(current_month<10){
              current_month=current_month+1;
            }
            var current_day = current_date.getUTCDate();
            if(current_day<10){
              current_day="0"+current_day;
            }
            var current_hours = current_date.getUTCHours();
            if(current_hours<10){
              current_hours="0"+current_hours;
            }
            var current_minutes = current_date.getUTCMinutes();
            if(current_minutes<10){
              current_minutes="0"+current_minutes;
            }
            var current_seconds = current_date.getUTCSeconds();
            if(current_seconds<10){
              current_seconds="0"+current_seconds;
            }
            
            var utc_date = current_date.getUTCFullYear() +"-"+ current_month + "-" + current_day +"T"+
 current_hours+":"+ current_minutes +":"+ current_seconds+"Z";
            console.log(utc_date);
            if(!this.report_text||!this.tool||!this.category){
                this.reporthasError=true;
            }
            else{
                  
                  urlencoded.append("study_id",userstore.state.firstName);
                  urlencoded.append("tool",this.tool);
                  urlencoded.append("topic",this.category);
                  urlencoded.append("description",this.report_text);
                  urlencoded.append("submission_date",utc_date);
                  urlencoded.append("submission_date_offset",Math.abs(current_date.getTimezoneOffset()*60));
                  urlencoded.append("status","unassigned");
                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body: urlencoded,
                  redirect: 'follow'
                };

                fetch("https://"+userstore.state.backurl+"/api/patient/ticket", requestOptions)
                  .then(response => {return response;})
                  .then(result => {
                    if(result.status==200&&this.lang=='en'){           
                      this.snackbarText="The report has been sent, thank you for your feedback";
                      this.showSnackbar=true;
                    }
                    else if(result.status!=204&&this.lang=='en'){
                      this.snackbarText="Connection error, please try again later";
                      this.showSnackbar=true;
                      }
                    else if(result.status==200&&this.lang=='it'){
                      this.snackbarText="The report has been sent, thank you for your feedback";
                      this.showSnackbar=true;
                      }
                    else if(result.status!=204&&this.lang=='it'){
                      this.snackbarText="Connection error, please try again later";
                      this.showSnackbar=true;
                      }
                      userstore.commit('changeneedsReset','');
                      window.location.href = 'select-questionnaire';
                    })
                  .catch(error => console.log('error', error));

            }

        },
        changeToEn(){
          userstore.commit('changeLang',"en");
        },
        changeToIt(){
          userstore.commit('changeLang',"it");
        },
    },

    mounted: function(){
        
    },
    components: {
    }
    }
</script>

<style scoped>

</style>
