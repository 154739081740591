<template>
  <div class="hello">
    <div id="questioncard" class="md-card md-theme-demo-light">
      <div v-if="lang == 'en'" class="choicediv">
        <a v-bind:class="disabledState" v-bind:href="start_questionaire_link">
          <md-button class="questionoption md-raised md-primary">Start Questionnaire ({{ visit_timing_literal }})</md-button>
        </a>
      </div>
      <div v-if="lang == 'it'" class="choicediv">
        <a v-bind:class="disabledState" v-bind:href="start_questionaire_link">
          <md-button class="questionoption md-raised md-primary">Inizia il questionario ({{ visit_timing_literal }})</md-button>
        </a>
      </div>
      <p class="error_message" style=""></p>

      <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration"
        :md-active.sync="showSnackbar" md-persistent>
        <div>{{ snackbarText }}</div>
        <div>
          <md-button class="md-primary" @click="showSnackbar = false">OK</md-button>
        </div>
      </md-snackbar>
    </div>
    <div class="footer">
      <img id="eufundlogo" src="@/assets/eu-fund-logo.png" />
    </div>
  </div>
</template>
<script>
/*@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');*/
import { userstore } from "../data/userstore.js";

export default {
  name: "Select",
  components: {
    // HelloWorld
  },
  data() {
    return {
      care: "",
      carenext: "",
      cbib: "",
      cbibnext: "",
      health: "",
      healthnext: "",
      headneck: "",
      headnecknext: "",
      qlq: "",
      qlqnext: "",
      showSnackbar: false,
      position: "center",
      duration: 4000,
      isInfinity: true,
      disabledState: "",
      snackbarText:
        "Hello, Completion and submission of the BD4QoL questionnaires is not eligible at the moment. You will be able to do so two (2) weeks before your next planned visit at the clinic.",
      study_id: userstore.state.firstName,
      start_questionaire_link: "/qlq-c30",
      lang: userstore.state.lang,
      visit_timing_literal: "-"
    };
  },
  methods: {},
  created: async function () {
    if (this.lang == "it") {
      this.snackbarText =
        "La compilazione e l’invio dei questionari da studio BD4QoL non è possibile al momento. Sarà possibile compilare i questionari a partire da due (2) settimane prima della prossima visita di controllo.";
    }
    window.scrollTo(0, 0);
    console.log(new Date().getTimezoneOffset());

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("username", userstore.state.firstName);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    await fetch(
      "https://" + userstore.state.backurl + "/api/patient/last/draft",
      requestOptions
    )
      .then((response) => {
        console.log(response.status);
        if (response.status == 200) {
          return response.json();
        } else {
          this.disabledState = "disabled";
          this.showSnackbar = true;
        }
      })
      .then((response) => {
        if (!response.inside2weeks) {
          this.disabledState = "disabled";
          this.showSnackbar = true;
        }
        console.log(response);
        var draftquestionnaire = response.questionnaire.type;
        this.visit_timing_literal = response.questionnaire.visit_timing_literal;
        console.log(draftquestionnaire);
        userstore.commit("changeDraftQuestionnaire", draftquestionnaire);
        if (draftquestionnaire == "quality_of_lives") {
          this.start_questionaire_link = "/qlq-c30";
        }
        if (draftquestionnaire == "head_neck_cancers") {
          this.start_questionaire_link = "/head-and-neck-cancer";
        }
        if (draftquestionnaire == "healths") {
          this.start_questionaire_link = "/health";
        }
        if (draftquestionnaire == "cbibs") {
          this.start_questionaire_link = "/cancer-behavior-inventory";
        }
        if (draftquestionnaire == "care_received") {
          this.start_questionaire_link = "/care-received";
        }
console.log(response);
        userstore.commit("changeDraftAnswers", response.answers);
        userstore.commit("changeCurrentQuestionnaires", response.questionnaires);
      })
      .catch((error) => console.log("error", error));
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

#backbutton {
  display: none;
}

.error_message {
  color: red;
  display: none;
}

.disabled {
  pointer-events: none;
}

.disabled button {
  background: #808080 !important;
}
</style>

