<template>
  <div class="care">

    <md-steppers :md-active-step.sync="horizontalactive" md-horizontal md-linear>
      <md-step id="first" :md-label="currentgeneralstrings[9]" :md-editable="false" :md-done="true">

      </md-step>

      <md-step id="second" :md-label="currentgeneralstrings[10]" :md-editable="false" :md-done="true">

      </md-step>

      <md-step id="third" :md-label="currentgeneralstrings[11]" :md-editable="false" :md-done="true">

      </md-step>

      <md-step id="fourth" :md-label="currentgeneralstrings[12]" :md-editable="false" :md-done="true">

      </md-step>

      <md-step id="fifth" :md-label="currentgeneralstrings[13]">

      </md-step>
    </md-steppers>

    <div id="questioncard" class="md-card md-theme-demo-light">
      <p v-if="lang == 'en'" class="section-title"> It is important to know your level of satisfaction with the care you
        receive. Please answer by selecting the option that best applies to you.
      </p>
      <p v-if="lang == 'it'" class="section-title">È importante conoscere il suo livello di soddisfazione per le cure
        ricevute. Risponda selezionando l'opzione più adatta.
      </p>

      <div>
        <md-steppers :md-active-step.sync="active" md-vertical md-linear>
          <md-step id="one" :md-label="currenttextset[0]" :md-error="StepOneError" :md-done.sync="one">
            <FormulateInput v-model="value1" :options="currentoptionset[0]" type="radio" />
            <!-- <md-button class="md-raised md-primary" @click="setDone('one', 'two')">Next question</md-button>-->
          </md-step>
        </md-steppers>
      </div>


      <FormulateInput id="submit" v-on:click="setError" type="submit" :label="currentgeneralstrings[3]" />


      <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration"
        :md-active.sync="showSnackbar" md-persistent>
        <span>{{ snackbarText }}</span>
        <md-button class="md-primary" @click="showSnackbar = false">OK</md-button>
      </md-snackbar>


      <md-dialog-confirm :md-active.sync="showAlert" :md-title="currentgeneralstrings[6]"
        :md-content="currentgeneralstrings[7]" :md-confirm-text="currentgeneralstrings[3]"
        :md-cancel-text="currentgeneralstrings[5]" @md-cancel="showAlert = false" @md-confirm="answerSubmit">
      </md-dialog-confirm>

      <div class="spinner">
        <bounce v-if="isLoading"></bounce>
      </div>


    </div>
  </div>
</template>
<script>
/*@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');*/
import { userstore } from '../data/userstore.js';


export default {
  name: 'StepperNonEditable',
  data() {
    return {
      result: 0,
      datasent: 0,
      //     answers:{},
      value1: "",
      publicPath: process.env.BASE_URL,
      active: 'one',
      horizontalactive: 'fifth',
      one: true,
      StepOneError: null,
      showSnackbar: false,
      position: 'center',
      duration: 4000,
      isInfinity: false,
      snackbarText: 'Submission error',
      showAlert: false,
      study_id: userstore.state.firstName,
      isLoading: false,
      isdraft: 0,
      lang: userstore.state.lang,
      questiontexten: ["Are you satisfied about the care you have received during the follow-up?"],
      questiontextit: ["È soddisfatto delle cure che ha ricevuto durante i controlli (follow-up)?"],
      questionoptionsen: [
        { 1: 'Absolutely Disagree', 2: 'Moderately Disagree', 3: 'Neither agree nor Disagree', 4: 'Moderately agree', 5: 'Absolutely agree' }
      ],
      questionoptionsit: [
        { 1: 'Assolutamente in disaccordo (non mi ritengo per nulla soddisfatto/a)', 2: 'Abbastanza in disaccordo', 3: 'Né d’accordo né in disaccordo', 4: 'Abbastanza d’accordo', 5: 'Assolutamente d’accordo (mi ritengo molto soddisfatto/a)' }
      ],
      generalstringsen: [
        "Next question",
        "Previous question",
        "Please answer question ",
        "Submit",
        "Save for later",
        "Cancel",
        "Alert",
        "Please note that some questions have not been answered. Press 'Submit' to skip these questions or 'Cancel' to continue answering.",
        "Please evaluate your overall health",
        "Quality of Life",
        "Head & Neck Cancer",
        "Health Questionnaire",
        "Cancer Behaviour Inventory",
        "Care received",
      ],
      generalstringsit: [
        "Prossima domanda",
        "Domanda precedente",
        "Per favore rispondi alla domanda",
        "Invia",
        "Salva per dopo",
        "Annulla",
        "Notifica",
        'Attenzione, non hai risposto ad alcune domande. Premi "Invia" per saltare queste domande oppure "Annulla" per continuare a rispondere.',
        "Per favore, valuta la tua salute",
        "EORTC QLQ-C30",
        "EORTC QLQ-H&N43",
        "EQ-5D-5L",
        "CBI-B",
        "PREM",
      ],
    }
  },
  methods: {
    setDone(id, index) {
      this[id] = true

      //  this.secondStepError = null

      if (index) {
        this.active = index
      }
    },
    saveForLater() {
      //save for later api call
      this.isdraft = 1;
      this.postAnswers();
    },
    setError() {
      if (this.value1 == "") {
        this.StepOneError = this.currentgeneralstrings[2] + " 1";
      }
      if (this.value1 == "") {
        this.snackbarText = "Please answer all questions";
        this.showAlert = true;
        this.showSnackbar = false;
      }
      else {
        this.result = Number(this.value1);
        console.log(this.result);
        this.postAnswers();
      }
    },
    answerSubmit() {

      this.postAnswers();
    },
    currentDateGreek() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      today = yyyy + '-' + mm + '-' + dd;
      return today;
    },
    postAnswers() {
      var questionable = [];
      this.isLoading = true;
      questionable = userstore.state.currentQuestionnaires.filter((q) => {
        return q.questionable_type === 'care_received';
      });
      console.log("questionable - " + questionable[0].questionable_id);
      if (this.datasent == 0) {
        var answers = [
          {
            "question_id": 91,
            "answer_integer": this.value1,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          }
        ];
        this.datasent = 1;
        console.log(answers);

        this.$http.post('https://' + userstore.state.backurl + '/api/questionnaires/care-received', {
          "answers": answers,
          "study_id": userstore.state.firstName,
          "date": userstore.state.submissionDate,
          "score": this.result,
          "questionable_id": questionable[0].questionable_id,
          headers: {}
        })
          .then(function (response) {
            console.log(response);
            this.showAlert = false;
            userstore.commit('changeId', null);
            if (userstore.state.isClinician) {
              window.location.href = "/list-questionnaires";
            }
            else {
              window.location.href = "/login?logoutstatus=completed";
            }

            //    this.$router.push({ path: '/login', query: { logoutstatus : 'completed' } });

          }).catch(function (response) {
            if (response.status != 200) {
              console.log(response);
            }
          });
      }
    }
  },
  created: function () {
    window.scrollTo(0, 0);

    if (userstore.state.lang == "en") {
      this.currentoptionset = this.questionoptionsen;
      this.currenttextset = this.questiontexten;
      this.currentgeneralstrings = this.generalstringsen;
    }
    else if (userstore.state.lang == "it") {
      this.currentoptionset = this.questionoptionsit;
      this.currenttextset = this.questiontextit;
      this.currentgeneralstrings = this.generalstringsit;
    }

    if (this.$route.query.alert) {
      this.snackbarText = this.$route.query.alert;
      //         this.showSnackbar=true;
    }
  },

}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
