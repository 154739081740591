<template>
  <div class="hello">



    <div id="homecard" class="md-card md-theme-demo-light">
      <img id="bd4qicon" src="@/assets/bd4qol-logo.jpg" />

      <div class="lang-menu">
        <div v-if="lang == 'en'" class="selected-lang en">
          English
        </div>
        <div v-if="lang == 'it'" class="selected-lang it">
          Italiano
        </div>
        <ul>
          <li>
            <span @click="changeToEn()" class="en">English</span>
          </li>
          <li>
            <span @click="changeToIt()" class="it">Italiano</span>
          </li>
        </ul>

      </div>

      <div class="loginmiddiv">
        <md-field>
          <label v-if="lang == 'en'">Username</label>
          <label v-if="lang == 'it'">Nome utente</label>
          <md-input v-model="username" required></md-input>

        </md-field>
      </div>
      <div class="loginmiddiv">
        <md-field>
          <label v-if="lang == 'en'">Password</label>
          <label v-if="lang == 'it'">Password</label>
          <md-input type="password" v-model="password" required></md-input>
        </md-field>
      </div>
      <div class="loginmiddiv">
        <md-button @click="Validation()" class="loginoption loginoptionright md-raised md-primary">Login</md-button>
        <md-button @click="ValidationAsClinician()" class="loginoption loginoptionright md-raised md-primary">Login as
          Clinician</md-button>
      </div>
      <div class="loginmiddiv">
        <a v-if="lang == 'en'" href="/forgot-password" style="text-align:center">Forgot Password</a>
        <a v-if="lang == 'it'" href="/forgot-password" style="text-align:center">Password dimenticata</a>
      </div>
      <div style="color:red !important;text-align:center;margin-top:10px;" v-if="loginerror && lang == 'en'">Wrong username
        or password</div>
      <div style="color:red !important;text-align:center;margin-top:10px;" v-if="loginerror && lang == 'it'">Nome utente o
        password errati</div>
      <div style="color:red !important;text-align:center;margin-top:10px;" v-if="withdrawnerror && lang == 'en'">Patient has withdrawn</div>
      <div style="color:red !important;text-align:center;margin-top:10px;" v-if="withdrawnerror && lang == 'it'">Il paziente si è ritirato</div>
      <!--   <div class="logindiv">
        <a href="/home"><md-button  class="loginoption  md-raised md-primary"><img src="@/assets/baseline_home_white_18dp.png"/>Home</md-button></a>
        <a href="/register"><md-button  class="loginoption loginoptionright md-raised md-primary"><img src="@/assets/baseline_how_to_reg_white_18dp.png"/>Register</md-button></a>
        </div> -->


    </div>
    <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar"
      md-persistent>
      <span>{{ snackbarText }}</span>
      <md-button class="md-primary" @click="showSnackbar = false">OK</md-button>
    </md-snackbar>
  </div>


</template>

<script>
//    import Alert from './Alert';
import { userstore } from '../data/userstore.js';
//    import {url} from '../data/url.js';




export default {
  name: 'add',
  data: () => ({
    //   noErrorPassword: false,
    //   noErrorUsername:false,
    username: null,
    password: null,
    passwordhasError: false,
    usernamehasError: false,
    //   passwordErrorText:'Invalid password',
    //   usernameErrorText:'Invalid username',
    loginerror: false,
    withdrawnerror: false,
    showSnackbar: false,
    position: 'center',
    duration: 4000,
    isInfinity: false,
    snackbarText: '',
    lang: userstore.state.lang,
    /*  generalstringsen:[
        "Username",
        "Password",
        "Forgot password",
        "Login"
      ],
      generalstringsit:[
        "Nome utente",
        "Parola d'ordine",
        "dimenticato la password",
        "Login"
      ],
      currentgeneralstrings:this.generalstringsen*/
  }),
  methods: {
    Validation() {

      if (!this.username) {
        this.loginerror = true;
      }
      else {
        this.loginerror = false;
      }

      if (!this.password) {
        this.loginerror = true;
      }
      else {
        this.loginerror = false;
      }
      if (!this.loginerror) {
        this.login();
      }


    },
    ValidationAsClinician() {

      if (!this.username) {
        this.loginerror = true;
      }
      else {
        this.loginerror = false;
      }

      if (!this.password) {
        this.loginerror = true;
      }
      else {
        this.loginerror = false;
      }
      if (!this.loginerror) {
        this.loginAsClinician();
      }
    },
    changeToEn() {
      userstore.commit('changeLang', "en");
    },
    changeToIt() {
      userstore.commit('changeLang', "it");
    },
    login() {
      var myusername = this.username;

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("grant_type", "password");
      urlencoded.append("client_id", "PoCtool");
      urlencoded.append("client_secret", "f8416dcf-f690-419c-8c14-57a524449ddf");
      urlencoded.append("username", this.username);
      urlencoded.append("password", this.password);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };


      var status = 0;

      fetch("https://" + userstore.state.backurl + "/api/patient/login", requestOptions)
        .then(response => { console.log(response.status); status = response.status; return response.json(); })
        .then(result => {
          console.log(result);
          if (status == 200) {
            userstore.commit('changeIsClinician', false);
            console.log(result);
            if (result.needs_password_reset) {
              userstore.commit('changeneedsReset', myusername);
              window.location.pathname = '/forgot-password';
            }
            else {
              userstore.commit('changefirstName', myusername);
              userstore.commit('changeId', myusername);
              userstore.commit('changelastName', myusername);
              console.log(myusername);
              window.location.pathname = '/select-questionnaire';
            }
            //   this.$router.push("/select-questionnaire");
          }
          else {
            if(result.error.description == "user has withdrawn"){
              this.withdrawnerror = true;
            }
            else{
              this.loginerror = true;
              this.withdrawnerror = false;
            }
          }

        })
        .catch(error => { this.loginerror = true; console.log('error', error); });

    },
    loginAsClinician() {
     var myusername = this.username;

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("grant_type", "password");
      urlencoded.append("client_id", "PoCtool");
      urlencoded.append("client_secret", "d3ae8b20-0d00-4ff0-b5a2-2223e0c36fe1");
      urlencoded.append("username", this.username);
      urlencoded.append("password", this.password);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };


      var status = 0;

      fetch("https://" + userstore.state.backurl + "/api/clinician/login", requestOptions)
        .then(response => { console.log(response.status); status = response.status; return response.json(); })
        .then(result => {
          console.log(result);
          if (status == 200) {
            console.log(result);
            if (result.needs_password_reset) {
              userstore.commit('changeneedsReset', myusername);
              window.location.pathname = '/forgot-password';
            }
            else {
              userstore.commit('changefirstName', myusername);
              userstore.commit('changeId', myusername);
              userstore.commit('changelastName', myusername);
              userstore.commit('changeIsClinician', true);
              console.log(myusername);
              window.location.pathname = '/list-questionnaires';
            }
            //   this.$router.push("/select-questionnaire");
          }
          else {
            this.loginerror = true;
          }

        })
        .catch(error => { this.loginerror = true; console.log('error', error); });

    },
  },
  created: function () {
    const urlParams = new URLSearchParams(window.location.search);
    userstore.commit('changeneedsReset', '');
    /* if(userstore.state.lang=="en"){
             this.currentgeneralstrings=this.generalstringsen;
     }
     else if(userstore.state.lang=="it"){
             this.currentgeneralstrings=this.generalstringsit;
     }*/
    const logoutParam = urlParams.get('logoutstatus');
    console.log(logoutParam);
    if (logoutParam == "completed") {
      userstore.commit('changeId', null);
      this.snackbarText = "You will be able to fill in the BD4QoL questionnaires once your next PoC visit is planned by you physician and the timing is two weeks around the due day.";
      this.showSnackbar = true;
    }
  },
  mounted: function () {
    console.log(userstore.state.id);

  },
  components: {
    //    Alert
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
