<template>
  <div class="hn43">

    <md-steppers :md-active-step.sync="horizontalactive" md-horizontal md-linear>
      <md-step id="first" :md-label="currentgeneralstrings[9]" :md-editable="false" :md-done="true">
      </md-step>

      <md-step id="second" :md-label="currentgeneralstrings[10]">
      </md-step>

      <md-step id="third" :md-label="currentgeneralstrings[11]">
      </md-step>

      <md-step id="fourth" :md-label="currentgeneralstrings[12]">
      </md-step>

      <md-step id="fifth" :md-label="currentgeneralstrings[13]">
      </md-step>

    </md-steppers>

    <div id="questioncard" class="md-card md-theme-demo-light">
      <p class="section-title"> {{ currentwording[0] }}
      </p>
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
      <div>
        <md-steppers :md-active-step.sync="active" md-vertical md-linear>
          <md-step id="one" :md-label="currenttextset[0]" :md-error="StepOneError" :md-done.sync="one">
            <FormulateInput v-model="value1" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('one', 'two')">{{ currentgeneralstrings[0] }}
            </md-button>
          </md-step>

          <md-step id="two" :md-label="currenttextset[1]" :md-error="StepTwoError" :md-done.sync="two">
            <FormulateInput v-model="value2" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('two', 'one')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('two', 'three')">{{ currentgeneralstrings[0] }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="three" :md-label="currenttextset[2]" :md-error="StepThreeError" :md-done.sync="three">
            <FormulateInput v-model="value3" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('three', 'two')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('three', 'four')">{{ currentgeneralstrings[0] }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="four" :md-label="currenttextset[3]" :md-error="StepFourError" :md-done.sync="four">
            <FormulateInput v-model="value4" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('four', 'three')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('four', 'five')">{{ currentgeneralstrings[0] }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="five" :md-label="currenttextset[4]" :md-error="StepFiveError" :md-done.sync="five">
            <FormulateInput v-model="value5" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('five', 'four')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('five', 'six')">{{ currentgeneralstrings[0] }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="six" :md-label="currenttextset[5]" :md-error="StepSixError" :md-done.sync="six">
            <FormulateInput v-model="value6" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('six', 'five')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('six', 'seven')">{{ currentgeneralstrings[0] }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="seven" :md-label="currenttextset[6]" :md-error="StepSevenError" :md-done.sync="seven">
            <FormulateInput v-model="value7" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('seven', 'six')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('seven', 'eight')">{{ currentgeneralstrings[0] }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="eight" :md-label="currenttextset[7]" :md-error="StepEightError" :md-done.sync="eight">
            <FormulateInput v-model="value8" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('eight', 'seven')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('eight', 'nine')">{{ currentgeneralstrings[0] }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="nine" :md-label="currenttextset[8]" :md-error="StepNineError" :md-done.sync="nine">
            <FormulateInput v-model="value9" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('nine', 'eight')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('nine', 'ten')">{{ currentgeneralstrings[0] }}
            </md-button>
          </md-step>

          <md-step id="ten" :md-label="currenttextset[9]" :md-error="StepTenError" :md-done.sync="ten">
            <FormulateInput v-model="value10" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('ten', 'nine')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('ten', 'eleven')">{{ currentgeneralstrings[0] }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="eleven" :md-label="currenttextset[10]" :md-error="StepElevenError" :md-done.sync="eleven">
            <FormulateInput v-model="value11" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('eleven', 'ten')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('eleven', 'twelve')">{{ currentgeneralstrings[0] }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="twelve" :md-label="currenttextset[11]" :md-error="StepTwelveError" :md-done.sync="twelve">
            <FormulateInput v-model="value12" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('twelve', 'eleven')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('twelve', 'thirteen')">{{ currentgeneralstrings[0]
            }}
            </md-button>
          </md-step>
          <md-step id="thirteen" :md-label="currenttextset[12]" :md-error="StepThirteenError" :md-done.sync="thirteen">
            <FormulateInput v-model="value13" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('thirteen', 'twelve')">{{ currentgeneralstrings[1]
            }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('thirteen', 'fourteen')">
              {{ currentgeneralstrings[0] }}</md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="fourteen" :md-label="currenttextset[13]" :md-error="StepFourteenError" :md-done.sync="fourteen">
            <FormulateInput v-model="value14" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('fourteen', 'thirteen')">
              {{ currentgeneralstrings[1] }}</md-button>
            <md-button class="md-raised md-primary" @click="setDone('fourteen', 'fifteen')">{{ currentgeneralstrings[0]
            }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="fifteen" :md-label="currenttextset[14]" :md-error="StepFifteenError" :md-done.sync="fifteen">
            <FormulateInput v-model="value15" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('fifteen', 'fourteen')">{{ currentgeneralstrings[1]
            }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('fifteen', 'sixteen')">{{ currentgeneralstrings[0]
            }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="sixteen" :md-label="currenttextset[15]" :md-error="StepSixteenError" :md-done.sync="sixteen">
            <FormulateInput v-model="value16" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('sixteen', 'fifteen')">{{ currentgeneralstrings[1]
            }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('sixteen', 'seventeen')">
              {{ currentgeneralstrings[0] }}</md-button>
          </md-step>

          <md-step id="seventeen" :md-label="currenttextset[16]" :md-error="StepSeventeenError"
            :md-done.sync="seventeen">
            <FormulateInput v-model="value17" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('seventeen', 'sixteen')">
              {{ currentgeneralstrings[1] }}</md-button>
            <md-button class="md-raised md-primary" @click="setDone('seventeen', 'eighteen')">
              {{ currentgeneralstrings[0] }}</md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="eighteen" :md-label="currenttextset[17]" :md-error="StepEighteenError" :md-done.sync="eighteen">
            <FormulateInput v-model="value18" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('eighteen', 'seventeen')">
              {{ currentgeneralstrings[1] }}</md-button>
            <md-button class="md-raised md-primary" @click="setDone('eighteen', 'nineteen')">
              {{ currentgeneralstrings[0] }}</md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="nineteen" :md-label="currenttextset[18]" :md-error="StepNineteenError" :md-done.sync="nineteen">
            <FormulateInput v-model="value19" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('nineteen', 'eighteen')">
              {{ currentgeneralstrings[1] }}</md-button>
            <md-button class="md-raised md-primary" @click="setDone('nineteen', 'twenty')">{{ currentgeneralstrings[0]
            }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="twenty" :md-label="currenttextset[19]" :md-error="StepTwentyError" :md-done.sync="twenty">
            <FormulateInput v-model="value20" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('twenty', 'nineteen')">{{ currentgeneralstrings[1]
            }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('twenty', 'twentyone')">{{ currentgeneralstrings[0]
            }}
            </md-button>
          </md-step>

          <md-step id="twentyone" :md-label="currenttextset[20]" :md-error="StepTwentyoneError"
            :md-done.sync="twentyone">
            <FormulateInput v-model="value21" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('twentyone', 'twenty')">{{ currentgeneralstrings[1]
            }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('twentyone', 'twentytwo')">
              {{ currentgeneralstrings[0] }}</md-button>
          </md-step>

          <md-step id="twentytwo" :md-label="currenttextset[21]" :md-error="StepTwentytwoError"
            :md-done.sync="twentytwo">
            <FormulateInput v-model="value22" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('twentytwo', 'twentyone')">
              {{ currentgeneralstrings[1] }}</md-button>
            <md-button class="md-raised md-primary" @click="setDone('twentytwo', 'twentythree')">
              {{ currentgeneralstrings[0] }}</md-button>
          </md-step>

          <md-step id="twentythree" :md-label="currenttextset[22]" :md-error="StepTwentythreeError"
            :md-done.sync="twentythree">
            <FormulateInput v-model="value23" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('twentythree', 'twentytwo')">
              {{ currentgeneralstrings[1] }}</md-button>
            <md-button class="md-raised md-primary" @click="setDone('twentythree', 'twentyfour')">
              {{ currentgeneralstrings[0] }}</md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="twentyfour" :md-label="currenttextset[23]" :md-error="StepTwentyfourError"
            :md-done.sync="twentyfour">
            <FormulateInput v-model="value24" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('twentyfour', 'twentythree')">
              {{ currentgeneralstrings[1] }}</md-button>
            <md-button class="md-raised md-primary" @click="setDone('twentyfour', 'twentyfive')">
              {{ currentgeneralstrings[0] }}</md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="twentyfive" :md-label="currenttextset[24]" :md-error="StepTwentyfiveError"
            :md-done.sync="twentyfive">
            <FormulateInput v-model="value25" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('twentyfive', 'twentyfour')">
              {{ currentgeneralstrings[1] }}</md-button>
            <md-button class="md-raised md-primary" @click="setDone('twentyfive', 'twentysix')">
              {{ currentgeneralstrings[0] }}</md-button>
          </md-step>

          <md-step id="twentysix" :md-label="currenttextset[25]" :md-error="StepTwentysixError"
            :md-done.sync="twentysix">
            <FormulateInput v-model="value26" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('twentysix', 'twentyfive')">
              {{ currentgeneralstrings[1] }}</md-button>
            <md-button class="md-raised md-primary" @click="setDone('twentysix', 'twentyseven')">
              {{ currentgeneralstrings[0] }}</md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="twentyseven" :md-label="currenttextset[26]" :md-error="StepTwentysevenError"
            :md-done.sync="twentyseven">
            <FormulateInput v-model="value27" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('twentyseven', 'twentysix')">
              {{ currentgeneralstrings[1] }}</md-button>
            <md-button class="md-raised md-primary" @click="setDone('twentyseven', 'twentyeight')">
              {{ currentgeneralstrings[0] }}</md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="twentyeight" :md-label="currenttextset[27]" :md-error="StepTwentyeightError"
            :md-done.sync="twentyeight">
            <FormulateInput v-model="value28" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('twentyeight', 'twentyseven')">
              {{ currentgeneralstrings[1] }}</md-button>
            <md-button class="md-raised md-primary" @click="setDone('twentyeight', 'twentynine')">
              {{ currentgeneralstrings[0] }}</md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="twentynine" :md-label="currenttextset[28]" :md-error="StepTwentynineError"
            :md-done.sync="twentynine">
            <FormulateInput v-model="value29" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('twelve', 'eleven')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('twentynine', 'thirty')">
              {{ currentgeneralstrings[0] }}</md-button>
          </md-step>

          <md-step id="thirty" :md-label="currenttextset[29]" :md-error="StepThirtyError" :md-done.sync="thirty">
            <FormulateInput v-model="value30" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('thirty', 'twentynine')">
              {{ currentgeneralstrings[1] }}</md-button>
            <md-button class="md-raised md-primary" @click="setDone('thirty', 'thirtyone')">{{ currentgeneralstrings[0]
            }}
            </md-button>
          </md-step>

          <md-step id="thirtyone" :md-label="currenttextset[30]" :md-error="StepThirtyoneError" :md-done.sync="thirty">
            <FormulateInput v-model="value31" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('thirtyone', 'thirty')">{{ currentgeneralstrings[1]
            }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('thirtyone', 'thirtytwo')">
              {{ currentgeneralstrings[0] }}</md-button>
          </md-step>
          <md-step id="thirtytwo" :md-label="currenttextset[31]" :md-error="StepThirtytwoError"
            :md-done.sync="thirtytwo">
            <FormulateInput v-model="value32" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('thirtytwo', 'thirtyone')">
              {{ currentgeneralstrings[1] }}</md-button>
            <md-button class="md-raised md-primary" @click="setDone('thirtytwo', 'thirtythree')">
              {{ currentgeneralstrings[0] }}</md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="thirtythree" :md-label="currenttextset[32]" :md-error="StepThirtythreeError"
            :md-done.sync="thirtythree">
            <FormulateInput v-model="value33" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('thirtythree', 'thirtytwo')">
              {{ currentgeneralstrings[1] }}</md-button>
            <md-button class="md-raised md-primary" @click="setDone('thirtythree', 'thirtyfour')">
              {{ currentgeneralstrings[0] }}</md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="thirtyfour" :md-label="currenttextset[33]" :md-error="StepThirtyfourError"
            :md-done.sync="thirtyfour">
            <FormulateInput v-model="value34" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('thirtyfour', 'thirtythree')">
              {{ currentgeneralstrings[1] }}</md-button>
            <md-button class="md-raised md-primary" @click="setDone('thirtyfour', 'thirtyfive')">
              {{ currentgeneralstrings[0] }}</md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="thirtyfive" :md-label="currenttextset[34]" :md-error="StepThirtyfiveError"
            :md-done.sync="thirtyfive">
            <FormulateInput v-model="value35" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('thirtyfive', 'thirtyfour')">
              {{ currentgeneralstrings[1] }}</md-button>
            <md-button class="md-raised md-primary" @click="setDone('thirtyfive', 'thirtysix')">
              {{ currentgeneralstrings[0] }}</md-button>
          </md-step>

          <md-step id="thirtysix" :md-label="currenttextset[35]" :md-error="StepThirtysixError"
            :md-done.sync="thirtysix">
            <FormulateInput v-model="value36" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('thirtysix', 'thirtyfive')">
              {{ currentgeneralstrings[1] }}</md-button>
            <md-button class="md-raised md-primary" @click="setDone('thirtysix', 'thirtyseven')">
              {{ currentgeneralstrings[0] }}</md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="thirtyseven" :md-label="currenttextset[36]" :md-error="StepThirtysevenError"
            :md-done.sync="thirtyseven">
            <FormulateInput v-model="value37" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('thirtyseven', 'thirtysix')">
              {{ currentgeneralstrings[1] }}</md-button>
            <md-button class="md-raised md-primary" @click="setDone('thirtyseven', 'thirtyeight')">
              {{ currentgeneralstrings[0] }}</md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="thirtyeight" :md-label="currenttextset[37]" :md-error="StepThirtyeightError"
            :md-done.sync="thirtyeight">
            <FormulateInput v-model="value38" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('thirtyeight', 'thirtyseven')">
              {{ currentgeneralstrings[1] }}</md-button>
            <md-button class="md-raised md-primary" @click="setDone('thirtyeight', 'thirtynine')">
              {{ currentgeneralstrings[0] }}</md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="thirtynine" :md-label="currenttextset[38]" :md-error="StepThirtynineError"
            :md-done.sync="thirtynine">
            <FormulateInput v-model="value39" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('thirtynine', 'thirtyeight')">
              {{ currentgeneralstrings[1] }}</md-button>
            <md-button class="md-raised md-primary" @click="setDone('thirtynine', 'forty')">{{ currentgeneralstrings[0]
            }}
            </md-button>
          </md-step>

          <md-step id="forty" :md-label="currenttextset[39]" :md-error="StepFortyError" :md-done.sync="forty">
            <FormulateInput v-model="value40" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('forty', 'thirtynine')">{{ currentgeneralstrings[1]
            }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('forty', 'fortyone')">{{ currentgeneralstrings[0] }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="fortyone" :md-label="currenttextset[40]" :md-error="StepFortyoneError" :md-done.sync="fortyone">
            <FormulateInput v-model="value41" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('fortyone', 'forty')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('fortyone', 'fortytwo')">
              {{ currentgeneralstrings[0] }}</md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="fortytwo" :md-label="currenttextset[41]" :md-error="StepFortytwoError" :md-done.sync="fortytwo">
            <FormulateInput v-model="value42" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('fortytwo', 'fortyone')">
              {{ currentgeneralstrings[1] }}</md-button>
            <md-button class="md-raised md-primary" @click="setDone('fortytwo', 'fortythree')">
              {{ currentgeneralstrings[0] }}</md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="fortythree" :md-label="currenttextset[42]" :md-error="StepFortythreeError"
            :md-done.sync="fortythree">
            <FormulateInput v-model="value43" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('fortythree', 'fortytwo')">
              {{ currentgeneralstrings[1] }}</md-button>
            <!-- <md-button class="md-raised md-primary" @click="setDone('third')">Done</md-button>-->
          </md-step>
        </md-steppers>
      </div>

      <div class="action-container">


        <FormulateInput id="saveforlater" v-on:click="saveForLater" type="submit" :label="currentgeneralstrings[4]" />

        <FormulateInput id="submit" v-on:click="setError" type="submit" :label="currentgeneralstrings[3]" />

      </div>


      <!--<strong>Answer1: <em>{{ value1 }}</em>!</strong>
    <strong>Answer2: <em>{{ value2 }}</em>!</strong>
    <strong>Answer3: <em>{{ value3 }}</em>!</strong>
    <strong>Answer4: <em>{{ value4 }}</em>!</strong>
    <strong>Answer5: <em>{{ value5 }}</em>!</strong>
    <strong>Answer6: <em>{{ value6 }}</em>!</strong>
    <strong>Answer7: <em>{{ value7 }}</em>!</strong>
    <strong>Answer8: <em>{{ value8 }}</em>!</strong>
    <strong>Answer9: <em>{{ value9 }}</em>!</strong>
    <strong>Answer10: <em>{{ value10 }}</em>!</strong>
    <strong>Answer11: <em>{{ value11 }}</em>!</strong>
    <strong>Answer12: <em>{{ value12 }}</em>!</strong>-->

      <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration"
        :md-active.sync="showSnackbar" md-persistent>
        <span>{{ snackbarText }}</span>
        <md-button class="md-primary" @click="showSnackbar = false">OK</md-button>
      </md-snackbar>

      <md-dialog-confirm :md-active.sync="showAlert" :md-title="currentgeneralstrings[6]"
        :md-content="currentgeneralstrings[7]" :md-confirm-text="currentgeneralstrings[3]"
        :md-cancel-text="currentgeneralstrings[5]" @md-cancel="showAlert = false" @md-confirm="answerSubmit">
      </md-dialog-confirm>

      <div class="spinner">
        <bounce v-if="isLoading"></bounce>
      </div>


    </div>
  </div>
</template>
<script>
/*@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');*/
import { userstore } from '../data/userstore.js';

export default {
  name: 'StepperNonEditable',
  data() {
    return {
      datasent: 0,
      result: 0,
      //     answers:{},
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      value5: "",
      value6: "",
      value7: "",
      value8: "",
      value9: "",
      value10: "",
      value11: "",
      value12: "",
      value13: "",
      value14: "",
      value15: "",
      value16: "",
      value17: "",
      value18: "",
      value19: "",
      value20: "",
      value21: "",
      value22: "",
      value23: "",
      value24: "",
      value25: "",
      value26: "",
      value27: "",
      value28: "",
      value29: "",
      value30: "",
      value31: "",
      value32: "",
      value33: "",
      value34: "",
      value35: "",
      value36: "",
      value37: "",
      value38: "",
      value39: "",
      value40: "",
      value41: "",
      value42: "",
      value43: "",
      publicPath: process.env.BASE_URL,
      active: 'one',
      horizontalactive: 'second',
      one: true,
      two: true,
      three: true,
      four: true,
      five: true,
      six: true,
      seven: true,
      eight: true,
      nine: true,
      ten: true,
      eleven: true,
      twelve: true,
      thirteen: true,
      fourteen: true,
      fifteen: true,
      sixteen: true,
      seventeen: true,
      eighteen: true,
      nineteen: true,
      twenty: true,
      twentyone: true,
      twentytwo: true,
      twentythree: true,
      twentyfour: true,
      twentyfive: true,
      twentysix: true,
      twentyseven: true,
      twentyeight: true,
      twentynine: true,
      thirty: true,
      thirtyone: true,
      thirtytwo: true,
      thirtythree: true,
      thirtyfour: true,
      thirtyfive: true,
      thirtysix: true,
      thirtyseven: true,
      thirtyeight: true,
      thirtynine: true,
      forty: true,
      fortyone: true,
      fortytwo: true,
      fortythree: true,
      StepOneError: null,
      StepTwoError: null,
      StepThreeError: null,
      StepFourError: null,
      StepFiveError: null,
      StepSixError: null,
      StepSevenError: null,
      StepEightError: null,
      StepNineError: null,
      StepTenError: null,
      StepElevenError: null,
      StepTwelveError: null,
      StepThirteenError: null,
      StepFourteenError: null,
      StepFifteenError: null,
      StepSixteenError: null,
      StepSeventeenError: null,
      StepEighteenError: null,
      StepNineteenError: null,
      StepTwentyError: null,
      StepTwentyoneError: null,
      StepTwentytwoError: null,
      StepTwentythreeError: null,
      StepTwentyfourError: null,
      StepTwentyfiveError: null,
      StepTwentysixError: null,
      StepTwentysevenError: null,
      StepTwentyeightError: null,
      StepTwentynineError: null,
      StepThirtyError: null,
      StepThirtyoneError: null,
      StepThirtytwoError: null,
      StepThirtythreeError: null,
      StepThirtyfourError: null,
      StepThirtyfiveError: null,
      StepThirtysixError: null,
      StepThirtysevenError: null,
      StepThirtyeightError: null,
      StepThirtynineError: null,
      StepFortyError: null,
      StepFortyoneError: null,
      StepFortytwoError: null,
      StepFortythreeError: null,
      showSnackbar: false,
      position: 'center',
      duration: 4000,
      isInfinity: false,
      snackbarText: 'Submission error',
      showAlert: false,
      study_id: userstore.state.firstName,
      isdraft: 0,
      isLoading: false,
      lang: userstore.state.lang,
      wordingen: ["Please complete this questionnaire to help us to know how confident you are that you can do the following things. Be sure your ratings are about your confidence even if you have not done it in the past. So, your ratings are about your confidence that you can do these things now or in the near future."],
      wordingit: ["Talvolta i pazienti lamentano i seguenti sintomi o problemi. La preghiamo di indicare in quale misura si sono manifestati questi sintomi o problemi durante gli ultimi sette giorni. Selezioni la risposta che meglio definisce la Sua situazione."],
      questiontexten: [
        "Question 31: Have you had pain in your mouth?",
        "Question 32: Have you had pain in your jaw?",
        "Question 33: Have you had soreness in your mouth?",
        "Question 34: Have you had pain in your throat?",
        "Question 35: Have you had problems swallowing liquids?",
        "Question 36: Have you had problems swallowing pureed food?",
        "Question 37: Have you had problems swallowing solid food?",
        "Question 38: Have you choked when swallowing?",
        "Question 39: Have you had problems with your teeth?",
        "Question 40: Have you had problems because of losing some teeth?",
        "Question 41: Have you had problems opening your mouth wide?",
        "Question 42: Have you had a dry mouth?",
        "Question 43: Have you had sticky saliva?",
        "Question 44: Have you had problems with your sense of smell?",
        "Question 45: Have you had problems with your sense of taste?",
        "Question 46: Have you had problems with coughing?",
        "Question 47: Have you had problems with hoarseness?",
        "Question 48: Have you had problems with your appearance?",
        "Question 49: Have you felt less physically attractive as a result of your disease or treatment?",
        "Question 50: Have you felt dissatisfied with your body?",
        "Question 51: Have you had problems eating?",
        "Question 52: Have you had problems eating in front of your family?",
        "Question 53: Have you had problems eating in front of other people?",
        "Question 54: Have you had problems enjoying your meals?",
        "Question 55: Have you had problems talking to other people?",
        "Question 56: Have you had problems talking on the telephone?",
        "Question 57: Have you had problems talking in a noisy environment?",
        "Question 58: Have you had problems speaking clearly?",
        "Question 59: Have you had problems going out in public?",
        "Question 60: Have you felt less interest in sex?",
        "Question 61: Have you felt less sexual enjoyment?",
        "Question 62: Have you had problems raising your arm or moving it sideways?",
        "Question 63: Have you had pain in your shoulder?",
        "Question 64: Have you had swelling in your neck?",
        "Question 65: Have you had skin problems (e.g. itchy, dry)?",
        "Question 66: Have you had a rash?",
        "Question 67: Has your skin changed colour?",
        "Question 68: Have you worried that your weight is too low?",
        "Question 69: Have you worried about the results of examinations and tests?",
        "Question 70: Have you worried about your health in the future?",
        "Question 71: Have you had problems with wounds healing?",
        "Question 72: Have you had tingling or numbness in your hands or feet?",
        "Question 73: Have you had problems chewing?"],
      questiontextit: [
        "Domanda 31:Ha avuto dolore in bocca?",
        "Domanda 32:Ha avuto dolore alla mascella?",
        "Domanda 33:Ha avuto infiammazione in bocca?",
        "Domanda 34:Ha avuto dolore alla gola?",
        "Domanda 35:Ha avuto problemi a ingoiare liquidi?",
        "Domanda 36:Ha avuto problemi a ingoiare cibi passati?",
        "Domanda 37:Ha avuto problemi a ingoiare cibi solidi?",
        "Domanda 38:Ha avuto un senso di soffocamento quando ingoia?",
        "Domanda 39:Ha avuto problemi ai denti?",
        "Domanda 40:Ha avuto problemi a causa della perdita di qualche dente?",
        "Domanda 41:Ha avuto problemi ad aprire bene la bocca?",
        "Domanda 42:Ha avuto secchezza alla bocca?",
        "Domanda 43:Ha avuto la saliva impastata?",
        "Domanda 44:Ha avuto problemi con il senso dell'odorato?",
        "Domanda 45:Ha avuto problemi con il senso del gusto?",
        "Domanda 46:Ha avuto problemi a tossire?",
        "Domanda 47:Ha avuto problemi per la voce bassa o roca?",
        "Domanda 48:Ha avuto problemi a causa del suo aspetto fisico?",
        "Domanda 49:Si è sentito/a fisicamente meno attraente per effetto della malattia o della terapia?",
        "Domanda 50:Si è sentito/a insoddisfatto/a del Suo corpo?",
        "Domanda 51:Ha avuto problemi a mangiare?",
        "Domanda 52:Ha avuto problemi a mangiare in presenza della Sua famiglia?",
        "Domanda 53:Ha avuto problemi a mangiare in presenza di altre persone?",
        "Domanda 54:Ha avuto problemi nell'apprezzare i pasti?",
        "Domanda 55:Ha avuto problemi nel parlare con gli altri?",
        "Domanda 56:Ha avuto problemi nel parlare al telefono?",
        "Domanda 57:Ha avuto problemi nel conversare in un ambiente rumoroso?",
        "Domanda 58:Ha avuto problemi a parlare con chiarezza?",
        "Domanda 59:Ha avuto problemi ad andare tra la gente?",
        "Domanda 60:Ha provato minore interesse per il sesso?",
        "Domanda 61:Ha provato minore piacere nel sesso?",
        "Domanda 62:Ha avuto problemi a sollevare il braccio o a muoverlo lateralmente?",
        "Domanda 63:Ha avuto dolori alla spalla?",
        "Domanda 64:Ha avuto gonfiore al collo?",
        "Domanda 65:Ha avuto problemi di pelle (es. prurito, secchezza)?",
        "Domanda 66:Ha notato la comparsa di eruzioni cutanee?",
        "Domanda 67:Il colorito della Sua pelle e cambiato?",
        "Domanda 68:Si è sentito/a preoccupato/a che il Suo peso fosse troppo basso?",
        "Domanda 69:Si è sentito/a preoccupato/a per i risultati dei controlli clinici e degli esami diagnostici?",
        "Domanda 70:Si è sentito/a preoccupato/a per la Sua salute in futuro?",
        "Domanda 71:Ha avuto problemi con la cicatrizzazione delle ferite?",
        "Domanda 72:Ha provato una sensazione di formicolio e/o avuto minore sensibilità alle mani o ai piedi?",
        "Domanda 73:Ha avuto problemi a masticare?"],
      questionoptionsen: [
        { 1: 'Not at All', 2: 'A Little', 3: 'Quite a Bit', 4: 'Very Much' },
      ],
      questionoptionsit: [
        { 1: 'No', 2: 'Un po\'', 3: 'Parecchio', 4: 'Moltissimo' },
      ],
      generalstringsen: [
        "Next question",
        "Previous question",
        "Please answer question ",
        "Submit",
        "Save for later",
        "Cancel",
        "Alert",
        "Please note that some questions have not been answered. Press 'Submit' to skip these questions or 'Cancel' to continue answering.",
        "Please evaluate your overall health",
        "Quality of Life",
        "Head & Neck Cancer",
        "Health Questionnaire",
        "Cancer Behaviour Inventory",
        "Care received",
      ],
      generalstringsit: [
        "Prossima domanda",
        "Domanda precedente",
        "Per favore rispondi alla domanda",
        "Invia",
        "Salva per dopo",
        "Annulla",
        "Notifica",
        'Attenzione, non hai risposto ad alcune domande. Premi "Invia" per saltare queste domande oppure "Annulla" per continuare a rispondere.',
        "Per favore, valuta la tua salute",
        "EORTC QLQ-C30",
        "EORTC QLQ-H&N43",
        "EQ-5D-5L",
        "CBI-B",
        "PREM",
      ],
      currentoptionset: this.questionoptionsen,
      currenttextset: this.questiontexten,
      currentgeneralstrings: this.generalstringsen,
      currentwording: this.wordingen,
    }
  },
  methods: {
    setDone(id, index) {
      this[id] = true

      //  this.secondStepError = null

      if (index) {
        this.active = index
      }
    },
    saveForLater() {
      this.isdraft = 1;
      this.postAnswers();
    },
    setError() {
      if (this.value1 == "") {
        this.StepOneError = this.currentgeneralstrings[2] + "31";
      }
      if (this.value2 == "") {
        this.StepTwoError = this.currentgeneralstrings[2] + "32";
      }
      if (this.value3 == "") {
        this.StepThreeError = this.currentgeneralstrings[2] + "33";
      }
      if (this.value4 == "") {
        this.StepFourError = this.currentgeneralstrings[2] + "34";
      }
      if (this.value5 == "") {
        this.StepFiveError = this.currentgeneralstrings[2] + "35";
      }
      if (this.value6 == "") {
        this.StepSixError = this.currentgeneralstrings[2] + "36";
      }
      if (this.value7 == "") {
        this.StepSevenError = this.currentgeneralstrings[2] + "37";
      }
      if (this.value8 == "") {
        this.StepEightError = this.currentgeneralstrings[2] + "38";
      }
      if (this.value9 == "") {
        this.StepNineError = this.currentgeneralstrings[2] + "39";
      }
      if (this.value10 == "") {
        this.StepTenError = this.currentgeneralstrings[2] + "40";
      }
      if (this.value11 == "") {
        this.StepElevenError = this.currentgeneralstrings[2] + "41";
      }
      if (this.value12 == "") {
        this.StepTwelveError = this.currentgeneralstrings[2] + "42";
      }
      if (this.value13 == "") {
        this.StepThirteenError = this.currentgeneralstrings[2] + "43";
      }
      if (this.value14 == "") {
        this.StepFourteenError = this.currentgeneralstrings[2] + "44";
      }
      if (this.value15 == "") {
        this.StepFifteenError = this.currentgeneralstrings[2] + "45";
      }
      if (this.value16 == "") {
        this.StepSixteenError = this.currentgeneralstrings[2] + "46";
      }
      if (this.value17 == "") {
        this.StepSeventeenError = this.currentgeneralstrings[2] + "47";
      }
      if (this.value18 == "") {
        this.StepEighteenError = this.currentgeneralstrings[2] + "48";
      }
      if (this.value19 == "") {
        this.StepNineteenError = this.currentgeneralstrings[2] + "49";
      }
      if (this.value20 == "") {
        this.StepTwentyError = this.currentgeneralstrings[2] + "50";
      }
      if (this.value21 == "") {
        this.StepTwentyoneError = this.currentgeneralstrings[2] + "51";
      }
      if (this.value22 == "") {
        this.StepTwentytwoError = this.currentgeneralstrings[2] + "52";
      }
      if (this.value23 == "") {
        this.StepTwentythreeError = this.currentgeneralstrings[2] + "53";
      }
      if (this.value24 == "") {
        this.StepTwentyfourError = this.currentgeneralstrings[2] + "54";
      }
      if (this.value25 == "") {
        this.StepTwentyfiveError = this.currentgeneralstrings[2] + "55";
      }
      if (this.value26 == "") {
        this.StepTwentysixError = this.currentgeneralstrings[2] + "56";
      }
      if (this.value27 == "") {
        this.StepTwentysevenError = this.currentgeneralstrings[2] + "57";
      }
      if (this.value28 == "") {
        this.StepTwentyeightError = this.currentgeneralstrings[2] + "58";
      }
      if (this.value29 == "") {
        this.StepTwentynineError = this.currentgeneralstrings[2] + "59";
      }
      if (this.value30 == "") {
        this.StepThirtyError = this.currentgeneralstrings[2] + "60";
      }
      if (this.value31 == "") {
        this.StepThirtyoneError = this.currentgeneralstrings[2] + "61";
      }
      if (this.value32 == "") {
        this.StepThirtytwoError = this.currentgeneralstrings[2] + "62";
      }
      if (this.value33 == "") {
        this.StepThirtythreeError = this.currentgeneralstrings[2] + "63";
      }
      if (this.value34 == "") {
        this.StepThirtyfourError = this.currentgeneralstrings[2] + "64";
      }
      if (this.value35 == "") {
        this.StepThirtyfiveError = this.currentgeneralstrings[2] + "65";
      }
      if (this.value36 == "") {
        this.StepThirtysixError = this.currentgeneralstrings[2] + "66";
      }
      if (this.value37 == "") {
        this.StepThirtysevenError = this.currentgeneralstrings[2] + "67";
      }
      if (this.value38 == "") {
        this.StepThirtyeightError = this.currentgeneralstrings[2] + "68";
      }
      if (this.value39 == "") {
        this.StepThirtynineError = this.currentgeneralstrings[2] + "69";
      }
      if (this.value40 == "") {
        this.StepFortyError = this.currentgeneralstrings[2] + "70";
      }
      if (this.value41 == "") {
        this.StepFortyoneError = this.currentgeneralstrings[2] + "71";
      }
      if (this.value42 == "") {
        this.StepFortytwoError = this.currentgeneralstrings[2] + "72";
      }
      if (this.value43 == "") {
        this.StepFortythreeError = this.currentgeneralstrings[2] + "73";
      }
      if (this.value1 == "" || this.value2 == "" || this.value3 == "" || this.value4 == "" || this.value5 == "" || this.value6 == "" || this.value7 == "" || this.value8 == "" || this.value9 == "" ||
        this.value10 == "" || this.value11 == "" || this.value12 == "" || this.value13 == "" || this.value14 == "" || this.value15 == "" || this.value16 == "" || this.value17 == "" || this.value18 == "" || this.value19 == "" ||
        this.value20 == "" || this.value21 == "" || this.value22 == "" || this.value23 == "" || this.value24 == "" || this.value25 == "" || this.value26 == "" || this.value27 == "" || this.value28 == "" || this.value29 == "" ||
        this.value30 == "" || this.value31 == "" || this.value32 == "" || this.value33 == "" || this.value34 == "" || this.value35 == "" || this.value36 == "" || this.value37 == "" || this.value38 == "" || this.value39 == "" ||
        this.value40 == "" || this.value41 == "" || this.value42 == "" || this.value43 == "") {
        this.snackbarText = "Please answer all questions";
        this.showAlert = true;
        this.showSnackbar = false;
      }
      else {



        this.postAnswers();

      }
    },
    answerSubmit() {

      this.postAnswers();

    },
    currentDateGreek() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      today = yyyy + '-' + mm + '-' + dd;
      return today;
    },
    postAnswers() {
      this.isLoading = true;
      //   var HNPA,swallowing,HNSE,HNSP,HNSO,HNSC,HNSX,HNTE,HNOM,HNDR,HNSS,HNCO,HNFI,HNPK,HNNU,HNFE,HNWL,HNWG;
      var pain_in_mouth, swallowing, problems_with_teeth, dry_mouth_sticky_saliva, problems_with_senses, speech, body_image, social_eating, sexuality, problems_with_shoulder, skin_problems, fear_of_progression,
        problems_opening_mouth, coughing, social_contact, swelling_in_the_neck, weight_loss, problems_wound_healing, neurological_problems;
      //   var setsanswered=19;
      var emptycounter = 0;
      //Computing Score
      var questionable = [];

      //pain_in_mouth
      if (this.value1 == '') {
        emptycounter++;
      }
      if (this.value2 == '') {
        emptycounter++;
      }
      if (this.value3 == '') {
        emptycounter++;
      }
      if (this.value4 == '') {
        emptycounter++;
      }

      if (emptycounter < 3) {
        pain_in_mouth = (Number(this.value1) + Number(this.value2) + Number(this.value3) + Number(this.value4)) / (4 - emptycounter);
        pain_in_mouth = (((pain_in_mouth - 1) / 3).toFixed(4)) * 100;
      }
      else {
        pain_in_mouth = -1;
        //   setsanswered--;
      }

      //swallowing
      emptycounter = 0;
      if (this.value5 == '') {
        emptycounter++;
      }
      if (this.value6 == '') {
        emptycounter++;
      }
      if (this.value7 == '') {
        emptycounter++;
      }
      if (this.value8 == '') {
        emptycounter++;
      }

      if (emptycounter < 3) {
        swallowing = (Number(this.value5) + Number(this.value6) + Number(this.value7) + Number(this.value8)) / (4 - emptycounter);
        swallowing = (((swallowing - 1) / 3).toFixed(4)) * 100;
      }
      else {
        swallowing = -1;
        //    setsanswered--;
      }


      //Problems with teeth
      emptycounter = 0;
      if (this.value9 == '') {
        emptycounter++;
      }
      if (this.value10 == '') {
        emptycounter++;
      }
      if (this.value43 == '') {
        emptycounter++;
      }

      if (emptycounter < 2) {
        problems_with_teeth = (Number(this.value9) + Number(this.value10) + Number(this.value43)) / (3 - emptycounter);
        problems_with_teeth = (((problems_with_teeth - 1) / 3).toFixed(4)) * 100;
      }
      else {
        problems_with_teeth = -1;
        //      setsanswered--;
      }


      //Dry mouth/sticky saliva
      emptycounter = 0;
      if (this.value12 == '') {
        emptycounter++;
      }
      if (this.value13 == '') {
        emptycounter++;
      }
      if (emptycounter < 1) {
        dry_mouth_sticky_saliva = (Number(this.value12) + Number(this.value13)) / (2 - emptycounter);
        dry_mouth_sticky_saliva = (((dry_mouth_sticky_saliva - 1) / 3).toFixed(4)) * 100;
      }
      else {
        dry_mouth_sticky_saliva = -1;
        //      setsanswered--;
      }

      //Problems with senses
      emptycounter = 0;
      if (this.value14 == '') {
        emptycounter++;
      }
      if (this.value15 == '') {
        emptycounter++;
      }
      if (emptycounter < 1) {
        problems_with_senses = (Number(this.value14) + Number(this.value15)) / (2 - emptycounter);
        problems_with_senses = (((problems_with_senses - 1) / 3).toFixed(4)) * 100;
      }
      else {
        problems_with_senses = 0;
        //     setsanswered--;
      }

      //Speech
      emptycounter = 0;
      if (this.value17 == '') {
        emptycounter++;
      }
      if (this.value25 == '') {
        emptycounter++;
      }
      if (this.value26 == '') {
        emptycounter++;
      }
      if (this.value27 == '') {
        emptycounter++;
      }
      if (this.value28 == '') {
        emptycounter++;
      }

      if (emptycounter < 3) {
        speech = (Number(this.value17) + Number(this.value25) + Number(this.value26) + Number(this.value27) + Number(this.value28)) / (5 - emptycounter);
        speech = (((speech - 1) / 3).toFixed(4)) * 100;
      }
      else {
        speech = -1;
        //     setsanswered--;
      }


      //Body image
      emptycounter = 0;
      if (this.value18 == '') {
        emptycounter++;
      }
      if (this.value19 == '') {
        emptycounter++;
      }
      if (this.value20 == '') {
        emptycounter++;
      }

      if (emptycounter < 2) {
        body_image = (Number(this.value18) + Number(this.value19) + Number(this.value20)) / (3 - emptycounter);
        body_image = (((body_image - 1) / 3).toFixed(4)) * 100;
      }
      else {
        body_image = -1;
        //    setsanswered--;
      }


      //Social eating
      emptycounter = 0;
      if (this.value24 == '') {
        emptycounter++;
      }
      if (this.value23 == '') {
        emptycounter++;
      }
      if (this.value21 == '') {
        emptycounter++;
      }
      if (this.value22 == '') {
        emptycounter++;
      }

      if (emptycounter < 3) {
        social_eating = (Number(this.value24) + Number(this.value23) + Number(this.value21) + Number(this.value22)) / (4 - emptycounter);
        social_eating = (((social_eating - 1) / 3).toFixed(4)) * 100;
      }
      else {
        social_eating = -1;
        //     setsanswered--;
      }


      //Sexuality
      emptycounter = 0;
      if (this.value30 == '') {
        emptycounter++;
      }
      if (this.value31 == '') {
        emptycounter++;
      }
      if (emptycounter < 1) {
        sexuality = (Number(this.value31) + Number(this.value30)) / (2 - emptycounter);
        sexuality = (((sexuality - 1) / 3).toFixed(4)) * 100;
      }
      else {
        sexuality = -1;
        //     setsanswered--;
      }

      //Problem with shoulder
      emptycounter = 0;
      if (this.value32 == '') {
        emptycounter++;
      }
      if (this.value33 == '') {
        emptycounter++;
      }
      if (emptycounter < 1) {
        problems_with_shoulder = (Number(this.value32) + Number(this.value33)) / (2 - emptycounter);
        problems_with_shoulder = (((problems_with_shoulder - 1) / 3).toFixed(4)) * 100;
      }
      else {
        problems_with_shoulder = -1;
        //     setsanswered--;
      }

      //Skin problems
      emptycounter = 0;
      if (this.value35 == '') {
        emptycounter++;
      }
      if (this.value36 == '') {
        emptycounter++;
      }
      if (this.value37 == '') {
        emptycounter++;
      }

      if (emptycounter < 2) {
        skin_problems = (Number(this.value35) + Number(this.value36) + Number(this.value37)) / (3 - emptycounter);
        skin_problems = (((skin_problems - 1) / 3).toFixed(4)) * 100;
      }
      else {
        skin_problems = -1;
        //    setsanswered--;
      }

      //Fear of progression
      emptycounter = 0;
      if (this.value39 == '') {
        emptycounter++;
      }
      if (this.value40 == '') {
        emptycounter++;
      }
      if (emptycounter < 1) {
        fear_of_progression = (Number(this.value39) + Number(this.value40)) / (2 - emptycounter);
        fear_of_progression = (((fear_of_progression - 1) / 3).toFixed(4)) * 100;
      }
      else {
        fear_of_progression = -1;
        //    setsanswered--;
      }


      //Problems opening mouth
      if (this.value11 != '') {
        problems_opening_mouth = Number(this.value11);
        problems_opening_mouth = (((problems_opening_mouth - 1) / 3).toFixed(4)) * 100;
      }
      else {
        problems_opening_mouth = -1;
        //    setsanswered--;
      }

      //Coughing
      if (this.value16 != '') {
        coughing = Number(this.value16);
        coughing = (((coughing - 1) / 3).toFixed(4)) * 100;
      }
      else {
        coughing = -1;
        //     setsanswered--;
      }

      //Social contact
      if (this.value29 != '') {
        social_contact = Number(this.value29);
        social_contact = (((social_contact - 1) / 3).toFixed(4)) * 100;
      }
      else {
        social_contact = -1;
        //    setsanswered--;
      }

      //swelling in the neck
      if (this.value34 != '') {
        swelling_in_the_neck = Number(this.value34);
        swelling_in_the_neck = (((swelling_in_the_neck - 1) / 3).toFixed(4)) * 100;
      }
      else {
        swelling_in_the_neck = -1;
        //      setsanswered--;
      }


      //weight loss
      if (this.value38 != '') {
        weight_loss = Number(this.value38);
        weight_loss = (((weight_loss - 1) / 3).toFixed(4)) * 100;
      }
      else {
        weight_loss = -1;
        //    setsanswered--;
      }


      //problems with wound healing
      if (this.value41 != '') {
        problems_wound_healing = Number(this.value41);
        problems_wound_healing = (((problems_wound_healing - 1) / 3).toFixed(4)) * 100;
      }
      else {
        problems_wound_healing = -1;
        //     setsanswered--;
      }

      //Neurological problems
      if (this.value42 != '') {
        neurological_problems = Number(this.value42);
        neurological_problems = (((neurological_problems - 1) / 3).toFixed(4)) * 100;
      }
      else {
        neurological_problems = -1;
        //     setsanswered--;
      }




      // this.result=((pain_in_mouth+swallowing+problems_with_teeth+dry_mouth_sticky_saliva+problems_with_senses+speech+body_image+social_eating+sexuality+problems_with_shoulder+skin_problems+fear_of_progression+
      //problems_opening_mouth+coughing+social_contact+swelling_in_the_neck+weight_loss+problems_wound_healing+neurological_problems)/setsanswered).toFixed(4);





      //   console.log(this.result);


      if (this.datasent == 0) {



        var answers = [
          {
            "question_id": 31,
            "answer_integer": this.value1,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 32,
            "answer_integer": this.value2,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 33,
            "answer_integer": this.value3,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 34,
            "answer_integer": this.value4,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 35,
            "answer_integer": this.value5,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 36,
            "answer_integer": this.value6,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 37,
            "answer_integer": this.value7,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 38,
            "answer_integer": this.value8,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 39,
            "answer_integer": this.value9,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 40,
            "answer_integer": this.value10,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 41,
            "answer_integer": this.value11,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 42,
            "answer_integer": this.value12,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 43,
            "answer_integer": this.value13,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 44,
            "answer_integer": this.value14,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 45,
            "answer_integer": this.value15,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 46,
            "answer_integer": this.value16,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 47,
            "answer_integer": this.value17,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 48,
            "answer_integer": this.value18,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 49,
            "answer_integer": this.value19,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 50,
            "answer_integer": this.value20,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 51,
            "answer_integer": this.value21,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 52,
            "answer_integer": this.value22,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 53,
            "answer_integer": this.value23,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 54,
            "answer_integer": this.value24,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 55,
            "answer_integer": this.value25,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 56,
            "answer_integer": this.value26,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 57,
            "answer_integer": this.value27,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 58,
            "answer_integer": this.value28,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 59,
            "answer_integer": this.value29,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 60,
            "answer_integer": this.value30,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 61,
            "answer_integer": this.value31,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 62,
            "answer_integer": this.value32,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 63,
            "answer_integer": this.value33,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 64,
            "answer_integer": this.value34,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 65,
            "answer_integer": this.value35,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 66,
            "answer_integer": this.value36,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 67,
            "answer_integer": this.value37,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 68,
            "answer_integer": this.value38,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 69,
            "answer_integer": this.value39,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 70,
            "answer_integer": this.value40,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 71,
            "answer_integer": this.value41,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 72,
            "answer_integer": this.value42,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 73,
            "answer_integer": this.value43,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
        ];
        this.datasent = 1;
        //     console.log(answers);

        console.log('pain_in_mouth:' + pain_in_mouth);
        console.log('swallowing:' + swallowing);
        console.log('problems_with_teeth:' + problems_with_teeth);
        console.log('dry_mouth_sticky_saliva:' + dry_mouth_sticky_saliva);
        console.log('problems_with_senses:' + problems_with_senses);
        console.log('speech:' + speech);
        console.log('body_image:' + body_image);
        console.log('social_eating:' + social_eating);
        console.log('sexuality:' + sexuality);
        console.log('problems_with_shoulder:' + problems_with_shoulder);
        console.log('skin_problems:' + skin_problems);
        console.log('fear_of_progression:' + fear_of_progression);
        console.log('problems_opening_mouth:' + problems_opening_mouth);
        console.log('coughing:' + coughing);
        console.log('social_contact:' + social_contact);
        console.log('swelling_in_the_neck:' + swelling_in_the_neck);
        console.log('weight_loss:' + weight_loss);
        console.log('problems_wound_healing:' + problems_wound_healing);
        console.log('neurological_problems:' + neurological_problems);

        //   console.log('result:'+this.result);
        questionable = userstore.state.currentQuestionnaires.filter((q) => {
          return q.questionable_type === 'head_neck_cancers';
        });
        console.log("questionable - " + questionable[0].questionable_id);
        if (this.isdraft == 0) {
          this.$http.post('https://' + userstore.state.backurl + '/api/questionnaires/head-neck-cancer', {
            "answers": answers,
            "study_id": userstore.state.firstName,
            "datetime": userstore.state.submissionDate,
            "pain_in_mouth": pain_in_mouth,
            "swallowing": swallowing,
            "problems_with_teeth": problems_with_teeth,
            "dry_mouth_sticky_saliva": dry_mouth_sticky_saliva,
            "problems_with_senses": problems_with_senses,
            "speech": speech,
            "body_image": body_image,
            "social_eating": social_eating,
            "sexuality": sexuality,
            "problems_with_shoulder": problems_with_shoulder,
            "skin_problems": skin_problems,
            "fear_of_progression": fear_of_progression,
            "problems_opening_mouth": problems_opening_mouth,
            "coughing": coughing,
            "social_contact": social_contact,
            "swelling_in_the_neck": swelling_in_the_neck,
            "weight_loss": weight_loss,
            "problems_wound_healing": problems_wound_healing,
            "neurological_problems": neurological_problems,
            "draft": this.isdraft,
            "questionable_id": questionable[0].questionable_id,
            headers: {}
          })
            .then(function (response) {
              console.log(response);
              this.$router.push("/health");

            }).catch(function (response) {
              if (response.status != 200) {
                console.log(response);
              }
              else {  //for testing while wating for api
                this.$router.push("/health");
              }
            });
        }
        else {
          this.$http.post('https://' + userstore.state.backurl + '/api/questionnaires/head-neck-cancer', {
            "answers": answers,
            "study_id": userstore.state.firstName,
            "datetime": userstore.state.submissionDate,
            "draft": this.isdraft,
            "questionable_id": questionable[0].questionable_id,
            headers: {}
          })
            .then(function (response) {
              console.log(response);
              userstore.commit('changeId', null);
              if (userstore.state.isClinician) {
                window.location.href = "/list-questionnaires";
              }
              else {
                window.location.href = "/login";
              }


            }).catch(function (response) {
              if (response.status != 200) {
                console.log(response);
              }
            });
        }
      }

    }

  },
  created: function () {
    window.scrollTo(0, 0);

    if (userstore.state.lang == "en") {
      this.currentoptionset = this.questionoptionsen;
      this.currenttextset = this.questiontexten;
      this.currentgeneralstrings = this.generalstringsen;
      this.currentwording = this.wordingen;
    }
    else if (userstore.state.lang == "it") {
      this.currentoptionset = this.questionoptionsit;
      this.currenttextset = this.questiontextit;
      this.currentgeneralstrings = this.generalstringsit;
      this.currentwording = this.wordingit;
    }

    if (userstore.state.draftquestionaire == "head_neck_cancers") {
      for (var i = 0; i < userstore.state.draftanswers.length; i++) {
        if (userstore.state.draftanswers[i].question_id == 31 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value1 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 32 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value2 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 33 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value3 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 34 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value4 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 35 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value5 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 36 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value6 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 37 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value7 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 38 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value8 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 39 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value9 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 40 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value10 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 41 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value11 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 42 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value12 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 43 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value13 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 44 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value14 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 45 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value15 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 46 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value16 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 47 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value17 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 48 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value18 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 49 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value19 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 50 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value20 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 51 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value21 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 52 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value22 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 53 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value23 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 54 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value24 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 55 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value25 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 56 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value26 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 57 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value27 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 58 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value28 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 59 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value29 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 60 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value30 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 61 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value31 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 62 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value32 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 63 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value33 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 64 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value34 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 65 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value35 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 66 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value36 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 67 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value37 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 68 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value38 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 69 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value39 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 70 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value40 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 71 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value41 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 72 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value42 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 73 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value43 = userstore.state.draftanswers[i].answer_integer;
        }
      }
    }


    if (this.$route.query.alert) {
      this.snackbarText = this.$route.query.alert;
      //        this.showSnackbar=true;
    }

  },

}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
