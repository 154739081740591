<template>
  <div class="hello">
    <div id="homecard"  class="md-card md-theme-demo-light">
        <img  id="bd4qicon" src="@/assets/bd4qol-logo.jpg"/>
        <div class="lang-menu">
          <div v-if="lang=='en'" class="selected-lang en">
              English
          </div>
          <div v-if="lang=='it'" class="selected-lang it">
              Italiano
          </div>
          <ul>
              <li>
                  <span  @click="changeToEn()" class="en">English</span>
              </li>
              <li>
                  <span  @click="changeToIt()" class="it">Italiano</span>
              </li>
          </ul>
          
        </div>
        <div class="loginmiddiv">
        <p v-if="lang=='en'">Insert your email to send you a link to reset your password.</p>
        <p v-if="lang=='it'">Inserisci il tuo indirizzo e-mail per resettare la password</p>

        </div>
        <div class="loginmiddiv">
            <md-field >
            <label v-if="lang=='en'">Email</label>
            <label v-if="lang=='it'">E-mail</label>

                <md-input v-model="email" required></md-input>
            </md-field>
            <span style="color:red !important;" v-if="emailhasError&&this.lang=='en'" >Insert a valid email, ex. "example@email.com"</span>
             <span style="color:red !important;" v-if="emailhasError&&this.lang=='it'" >Inserisci una e-mail valida, es. "example@email.com"</span>
            

        </div>
        <div class="loginmiddiv">
        <md-button v-if="lang=='en'" @click="Validation()"  class="loginoption  md-raised md-primary">Submit</md-button>
        <md-button v-if="lang=='it'" @click="Validation()"  class="loginoption  md-raised md-primary">Invia</md-button>
        
        </div>

        <div class="loginmiddiv">
        <a href="/login"><md-button  class="loginoption  md-raised md-primary">Login</md-button></a>
        </div>

          <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar" md-persistent>
        <div>{{ snackbarText }}</div>
        <div>
          <md-button class="md-primary" @click="showSnackbar = false">OK</md-button>
        </div>
      </md-snackbar>
     </div>
  </div>


</template>

<script>
import {userstore} from '../data/userstore.js';



    export default {
    name: 'add',
    data: () => ({
      showSnackbar:false,
      position: 'center',
      duration: 4000,
      isInfinity: true,
      snackbarText:'',
      noError: null,
      email: null,
      emailhasError: false,
      lang:userstore.state.lang,
      userneedsreset:userstore.state.needsReset,
    }),
    methods: {
        Validation(){


            if(!this.email){
                this.emailhasError=true;
                console.log(this.emailhasError);

            }
            else{
                this.emailhasError=false;
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                var urlencoded = new URLSearchParams();
                urlencoded.append("email", this.email);
                if(this.userneedsreset){
                  urlencoded.append("study_id",this.userneedsreset);
                  console.log("user needs reset "+this.userneedsreset);
                }
                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body: urlencoded,
                  redirect: 'follow'
                };

                fetch("https://"+userstore.state.backurl+"/api/patient/password-reset", requestOptions)
                  .then(response => {return response;})
                  .then(result => {
                    if(result.status==204&&this.lang=='en'){           
                      this.snackbarText="Further instructions regarding your password reset, have been sent to your email";
                      this.showSnackbar=true;
                    }
                    else if(result.status!=204&&this.lang=='en'){
                      this.snackbarText="This email has not been registered";
                      this.showSnackbar=true;
                      }
                    else if(result.status==204&&this.lang=='it'){
                      this.snackbarText="Le istruzioni per reimpostare la password sono state inviate via email";
                      this.showSnackbar=true;
                      }
                    else if(result.status!=204&&this.lang=='it'){
                      this.snackbarText="Questo indirizzo email non è registrato";
                      this.showSnackbar=true;
                      }
                      userstore.commit('changeneedsReset','');
                    })
                  .catch(error => console.log('error', error));

            }

        },
        changeToEn(){
          userstore.commit('changeLang',"en");
        },
        changeToIt(){
          userstore.commit('changeLang',"it");
        },
    },

    mounted: function(){
        
    },
    components: {
    }
    }
</script>

<style scoped>

</style>
