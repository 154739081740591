<template>
  <div class="health">

    <md-steppers :md-active-step.sync="horizontalactive" md-horizontal md-linear>
      <md-step id="first" :md-label="currentgeneralstrings[9]" :md-editable="false" :md-done="true">

      </md-step>

      <md-step id="second" :md-label="currentgeneralstrings[10]" :md-editable="false" :md-done="true">

      </md-step>

      <md-step id="third" :md-label="currentgeneralstrings[11]">

      </md-step>

      <md-step id="fourth" :md-label="currentgeneralstrings[12]">

      </md-step>

      <md-step id="fifth" :md-label="currentgeneralstrings[13]">

      </md-step>
    </md-steppers>

    <div id="questioncard" class="md-card md-theme-demo-light">
      <p v-if="lang == 'en'" class="section-title"> Under each question, please select the ONE that best describes your
        health TODAY.
      </p>
      <p v-if="lang == 'it'" class="section-title">Sotto ciascun argomento, selezioni una casella (UNA SOLA)
        che descrive meglio la
        sua salute OGGI.
      </p>
      <div>
        <md-steppers :md-active-step.sync="active" md-vertical md-linear>
          <md-step id="one" :md-label="currenttextset[0]" :md-error="StepOneError" :md-done.sync="one">
            <FormulateInput v-model="value1" :options="currentoptionset[0]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('one', 'two')">{{ currentgeneralstrings[0] }}
            </md-button>

          </md-step>

          <md-step id="two" :md-label="currenttextset[1]" :md-error="StepTwoError" :md-done.sync="two">
            <FormulateInput v-model="value2" :options="currentoptionset[1]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('two', 'one')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('two', 'three')">{{ currentgeneralstrings[0] }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="three" :md-label="currenttextset[2]" :md-error="StepThreeError" :md-done.sync="three">
            <FormulateInput v-model="value3" :options="currentoptionset[2]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('three', 'two')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('three', 'four')">{{ currentgeneralstrings[0] }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="four" :md-label="currenttextset[3]" :md-error="StepFourError" :md-done.sync="four">
            <FormulateInput v-model="value4" :options="currentoptionset[3]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('four', 'three')">{{ currentgeneralstrings[1] }}
            </md-button>
            <md-button class="md-raised md-primary" @click="setDone('four', 'five')">{{ currentgeneralstrings[0] }}
            </md-button>
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>

          <md-step id="five" :md-label="currenttextset[4]" :md-error="StepFiveError" :md-done.sync="five">
            <FormulateInput v-model="value5" :options="currentoptionset[4]" type="radio" />
            <md-button class="md-raised md-primary" @click="setDone('five', 'four')">{{ currentgeneralstrings[1] }}
            </md-button>
            <!--  <md-button class="md-raised md-primary" @click="setDone('five', 'six')">Next question</md-button>-->
            <!-- <md-button class="md-raised md-primary" @click="setError()">Set error!</md-button>-->
          </md-step>
        </md-steppers>

      </div>

      <p>{{ currenttextset[6] }}</p>


      <md-field style="width:200px">
        <label>{{ currenttextset[5] }}</label>
        <md-input style="width:200px" v-model="healthvalue" type="number" max="100" min="0">0</md-input>
      </md-field>

      <div class="action-container">


        <FormulateInput id="saveforlater" v-on:click="saveForLater" type="submit" :label="currentgeneralstrings[4]" />

        <FormulateInput id="submit" v-on:click="setError" type="submit" :label="currentgeneralstrings[3]" />

      </div>


      <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration"
        :md-active.sync="showSnackbar" md-persistent>
        <div>{{ snackbarText }}</div>
        <div>
          <md-button class="md-primary" @click="showSnackbar = false">OK</md-button>
          <md-button v-if="ShowDecline" class="md-primary" @click="answerDecline">DECLINE</md-button>
        </div>
      </md-snackbar>

      <md-dialog-confirm :md-active.sync="showAlert" :md-title="currentgeneralstrings[6]"
        :md-content="currentgeneralstrings[7]" :md-confirm-text="currentgeneralstrings[3]"
        :md-cancel-text="currentgeneralstrings[5]" @md-cancel="showAlert = false" @md-confirm="answerSubmit">
      </md-dialog-confirm>

      <div class="spinner">
        <bounce v-if="isLoading"></bounce>
      </div>

    </div>
  </div>

</template>
<script>
/*@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');*/
import { userstore } from '../data/userstore.js';

export default {
  // components: { Stepper },
  name: 'StepperNonEditable',
  data() {
    return {
      datasent: 0,
      result: "",
      severityindex: 0,
      healthindex: 0,
      //     answers:{},
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      value5: "",
      healthvalue: "",
      publicPath: process.env.BASE_URL,
      active: 'one',
      horizontalactive: 'third',
      one: true,
      two: true,
      three: true,
      four: true,
      five: true,
      ShowDecline: false,
      StepOneError: null,
      StepTwoError: null,
      StepThreeError: null,
      StepFourError: null,
      StepFiveError: null,
      notanswered: [],
      showSnackbar: false,
      showAlert: false,
      position: 'center',
      duration: 4000,
      isInfinity: true,
      snackbarText: 'Submission error',
      study_id: userstore.state.firstName,
      isdraft: 0,
      isLoading: false,
      sum: 0,
      lang: userstore.state.lang,
      questiontexten: ["Question 74: MOBILITY",
        "Question 75: SELF-CARE",
        "Question 76: USUAL ACTIVITIES (e.g. work, study, housework, family or leisure activities)",
        "Question 77: PAIN/DISCOMFORT",
        "Question 78: ANXIETY/DEPRESSION",
        "Your health today", "We would like to know how good or bad your health is TODAY. This scale is numbered from 0 to 100. 100 means the best health you can imagine. 0 means the worst health you can imagine. Please, write down the number that indicates how your health is TODAY."],
      questiontextit: ["DOMANDA 74:CAPACITÀ DI MOVIMENTO",
        "DOMANDA 75:CURA DELLA PERSONA",
        "DOMANDA 76:ATTIVITÀ ABITUALI (per es. lavoro, studio, lavori domestici,attività familiari o di svago)",
        "DOMANDA 77:DOLORE O FASTIDIO",
        "DOMANDA 78:ANSIA O DEPRESSIONE",
        "LA SUA SALUTE OGGI", "Vorremmo sapere quanto è buona o cattiva la Sua salute OGGI, in una scala che va da 0 a 100. 100 rappresenta la migliore salute che può immaginare, 0 rappresenta la peggiore salute che può immaginare. Scriva nella casella qui sotto il numero che meglio corrisponde alla Sua salute OGGI."],
      questionoptionsen: [
        { 1: 'I have no problems in walking about', 2: 'I have slight problems in walking about', 3: 'I have moderate problems in walking about', 4: 'I have severe problems in walking about', 5: 'I am unable to walk about' },
        { 1: 'I have no problem washing or dressing myself', 2: 'I have slight problems washing or dressing myself', 3: 'I have moderate problems washing or dressing myself', 4: 'I have severe problems washing or dressing myself', 5: 'I am unable to wash or dress myself' },
        { 1: 'I have no problem doing my usual activities', 2: 'I have slight problems doing my usual activities', 3: 'I have moderate problems doing my usual activities', 4: 'I have severe problems doing my usual activities', 5: 'I am unable to do my usual activities' },
        { 1: ' I have no pain or discomfort ', 2: ' I have slight pain or discomfort ', 3: ' I have moderate pain or discomfort ', 4: ' I have severe pain or discomfort ', 5: ' I have extreme pain or discomfort ' },
        { 1: ' I am not anxious or depressed ', 2: ' I am slight anxious or depressed ', 3: ' I am moderate anxious or depressed ', 4: ' I am severe anxious or depressed ', 5: ' I am extremely anxious or depressed ' }
      ],
      questionoptionsit: [
        { 1: 'Non ho difficoltà nel camminare', 2: 'Ho lievi difficoltà nel camminare', 3: 'Ho moderate difficoltà nel camminare', 4: 'Ho gravi difficoltà nel camminare', 5: 'Non sono in grado di camminare' },
        { 1: 'Non ho difficoltà nel lavarmi o vestirmi', 2: 'Ho lievi difficoltà nel lavarmi o vestirmi', 3: 'Ho moderate difficoltà nel lavarmi o vestirmi', 4: 'Ho gravi difficoltà nel lavarmi o vestirmi', 5: 'Non sono in grado di lavarmi o vestirmi' },
        { 1: 'Non ho difficoltà nello svolgimento delle attività abituali', 2: 'Ho lievi difficoltà nello svolgimento delle attività abituali', 3: 'Ho moderate difficoltà nello svolgimento delle attività abituali', 4: 'Ho gravi difficoltà nello svolgimento delle attività abituali', 5: 'Non sono in grado di svolgere le mie attività abituali' },
        { 1: 'Non provo alcun dolore o fastidio', 2: 'Provo lieve dolore o fastidio', 3: 'Provo moderato dolore o fastidio', 4: 'Provo grave dolore o fastidio', 5: 'Provo estremo dolore o fastidio' },
        { 1: 'Non sono ansioso/a o depresso/a', 2: ' Sono lievemente ansioso/a o depresso/a ', 3: 'Sono moderatamente ansioso/a o depresso/a', 4: ' Sono gravemente ansioso/a o depresso/a ', 5: ' Sono estremamente ansioso/a o depresso/a' }
      ],
      generalstringsen: [
        "Next question",
        "Previous question",
        "Please answer question ",
        "Submit",
        "Save for later",
        "Cancel",
        "Alert",
        "Please note that some questions have not been answered. Press 'Submit' to skip these questions or 'Cancel' to continue answering.",
        "Please evaluate your overall health",
        "Quality of Life",
        "Head & Neck Cancer",
        "Health Questionnaire",
        "Cancer Behaviour Inventory",
        "Care received",
      ],
      generalstringsit: [
        "Prossima domanda",
        "Domanda precedente",
        "Per favore rispondi alla domanda",
        "Invia",
        "Salva per dopo",
        "Annulla",
        "Notifica",
        'Attenzione, non hai risposto ad alcune domande. Premi "Invia" per saltare queste domande oppure "Annulla" per continuare a rispondere.',
        "Per favore, valuta la tua salute",
        "EORTC QLQ-C30",
        "EORTC QLQ-H&N43",
        "EQ-5D-5L",
        "CBI-B",
        "PREM",
      ],
      currentoptionset: this.questionoptionsen,
      currenttextset: this.questiontexten,
      currentgeneralstrings: this.generalstringsen,
    }
  },
  watch: {
    healthvalue: function (newHealth) {
      if (newHealth >= 101) {
        this.healthvalue = -1;
      }
      if (newHealth < 0) {
        this.healthvalue = -1;
      }
    }
  },
  methods: {
    setDone(id, index) {
      this[id] = true

      //  this.secondStepError = null

      if (index) {
        this.active = index
      }
    },
    saveForLater() {
      //save for later api call
      this.isdraft = 1;
      this.postAnswers();
    },
    /*   getAlertText(){
         return this.snackbarText;
       },*/
    setError() {
      console.log(this.$router);
      if (this.value1 == "") {
        this.StepOneError = this.currentgeneralstrings[2] + "74";
        //    this.notanswered.push(74);
      }
      if (this.value2 == "") {
        this.StepTwoError = this.currentgeneralstrings[2] + "75";
        //    this.notanswered.push(75);
      }
      if (this.value3 == "") {
        this.StepThreeError = this.currentgeneralstrings[2] + "76";
        //    this.notanswered.push(76);
      }
      if (this.value4 == "") {
        this.StepFourError = this.currentgeneralstrings[2] + "77";
        //    this.notanswered.push(77);
      }
      if (this.value5 == "") {
        this.StepFiveError = this.currentgeneralstrings[2] + "78";
        //     this.notanswered.push(78);
      }
      /* if(this.healthvalue==""){
         this.StepFiveError=this.currentgeneralstrings[8];
   //      this.notanswered.push('overall health evaluation');
       }*/
      if (this.value1 == "" || this.value2 == "" || this.value3 == "" || this.value4 == "" || this.value5 == "" || this.healthvalue == "") {
        /*  this.ShowDecline=true;
          var errortext="";
          if(this.notanswered.length==1){
            errortext="Please note that question ";
          }
          else{
            errortext="Please note that questions ";
          }
          for(var i=0;i<this.notanswered.length;i++){
            if(i==this.notanswered.length-1){
              errortext=errortext+this.notanswered[i]+" ";
            }
            else if(i==this.notanswered.length-2&&this.notanswered.length>1){
              errortext=errortext+this.notanswered[i]+" and ";
            }
            else{
              errortext=errortext+this.notanswered[i]+", ";
            }
          }
          errortext=errortext+"are missing.Please, fill them in or decline answer.";
          
          this.snackbarText=errortext;
          
          
          this.notanswered=[];
          console.log(this.notanswered);*/
        this.showAlert = true;
        this.showSnackbar = false;
      }
      else {
        this.ShowDecline = false;
        //   this.result=this.value1+this.value2+this.value3+this.value4+this.value5;
        //  console.log(this.result);
        this.showAlert = false;
        this.postAnswers();

      }
    },
    answerSubmit() {
      this.ShowDecline = false;
      this.showAlert = false;
      this.postAnswers();

    },
    currentDateGreek() {
      var today = new Date();
      //      var dd = String(today.getDate()).padStart(2, '0');
      //      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      //      var yyyy = today.getFullYear();

      //     today = yyyy + '-' + mm + '-' + dd;
      return today;
    },
    postAnswers() {
      this.isLoading = true;
      var answeredquestions = 5;
      var questionable = [];

      if (this.value1 == '') {
        this.value1 = '9';
        answeredquestions--;
      }
      if (this.value2 == '') {
        this.value2 = '9';
        answeredquestions--;
      }
      if (this.value3 == '') {
        this.value3 = '9';
        answeredquestions--;
      }
      if (this.value4 == '') {
        this.value4 = '9';
        answeredquestions--;
      }
      if (this.value5 == '') {
        this.value5 = '9';
        answeredquestions--;
      }

      this.sum = 0;

      if (this.value1 != '9') {
        this.sum += Number(this.value1);
      }
      if (this.value2 != '9') {
        this.sum += Number(this.value2);
      }
      if (this.value3 != '9') {
        this.sum += Number(this.value3);
      }
      if (this.value4 != '9') {
        this.sum += Number(this.value4);
      }
      if (this.value5 != '9') {
        this.sum += Number(this.value5);
      }

      this.result = this.sum;

      console.log("sum:" + this.sum);
      console.log("answered questions:" + answeredquestions);

      if (answeredquestions != 0) {
        var mean = this.sum / 5;
        this.severityindex = ((mean-1)/4) * 100;
        this.healthindex = 100 - this.severityindex;
      }
      else{
        this.severityindex = -1;
        this.healthindex = -1;
      }

      console.log("severity:" + this.severityindex);
      console.log("health:" + this.healthindex);

      if (this.datasent == 0) {
        this.datasent = 1;
        var answers = [
          {
            "question_id": 74,
            "answer_integer": this.value1,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 75,
            "answer_integer": this.value2,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 76,
            "answer_integer": this.value3,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 77,
            "answer_integer": this.value4,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 78,
            "answer_integer": this.value5,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          },
          {
            "question_id": 100,
            "answer_integer": this.healthvalue,
            "study_id": userstore.state.firstName,
            "draft": this.isdraft
          }
        ];
        this.datasent = 1;
        console.log(answers);
        questionable = userstore.state.currentQuestionnaires.filter((q) => {
          return q.questionable_type === 'healths';
        });
        console.log("questionable - " + questionable[0].questionable_id);
        //     this.$http.post('https://bd4qol.ddns.net/questionnaires/saveHealth', {
        if (this.isdraft == 0) {
          this.$http.post('https://' + userstore.state.backurl + '/api/questionnaires/health', {
            "answers": answers,
            "five_digit_code": parseInt(this.result),
            "severity_index": this.severityindex,
            "health_index": this.healthindex,
            "your_health_today": this.healthvalue,
            "study_id": userstore.state.firstName,
            //       "datetime":this.currentDateGreek(),
            "datetime": userstore.state.submissionDate,
            "draft": this.isdraft,
            "questionable_id": questionable[0].questionable_id,
            //        "score":parseInt(this.result),
            headers: {}
          })
            .then(function (response) {
              console.log(response);
              this.$router.push("/cancer-behavior-inventory");

              /*   if(response.body.id){
                     userstore.commit('changeId',response.body.id);


                 }*/
            }).catch(function (response) {
              if (response.status != 200) {
                console.log(response);
              }
              else {  //for testing while wating for api
                this.$router.push("/cancer-behavior-inventory");
              }
            });
        }
        else {
          this.$http.post('https://' + userstore.state.backurl + '/api/questionnaires/health', {
            "answers": answers,
            "study_id": userstore.state.firstName,
            //       "datetime":this.currentDateGreek(),
            "datetime": userstore.state.submissionDate,
            "draft": this.isdraft,
            "questionable_id": questionable[0].questionable_id,
            //        "score":parseInt(this.result),
            headers: {}
          })
            .then(function (response) {
              console.log(response);
              userstore.commit('changeId', null);
              if (userstore.state.isClinician) {
                window.location.href = "/list-questionnaires";
              }
              else {
                window.location.href = "/login";
              }

              /*   if(response.body.id){
                      userstore.commit('changeId',response.body.id);


                  }*/
            }).catch(function (response) {
              if (response.status != 200) {
                console.log(response);
              }
            });
        }
      }
    }
  },
  created: function () {
    window.scrollTo(0, 0);

    if (userstore.state.lang == "en") {
      this.currentoptionset = this.questionoptionsen;
      this.currenttextset = this.questiontexten;
      this.currentgeneralstrings = this.generalstringsen;
    }
    else if (userstore.state.lang == "it") {
      this.currentoptionset = this.questionoptionsit;
      this.currenttextset = this.questiontextit;
      this.currentgeneralstrings = this.generalstringsit;
    }

    if (userstore.state.draftquestionaire == "healths") {
      for (var i = 0; i < userstore.state.draftanswers.length; i++) {
        if (userstore.state.draftanswers[i].question_id == 74 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value1 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 75 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value2 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 76 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value3 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 77 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value4 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 78 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.value5 = userstore.state.draftanswers[i].answer_integer;
        }
        else if (userstore.state.draftanswers[i].question_id == 100 && userstore.state.draftanswers[i].answer_integer != -1) {
          console.log(i);
          this.healthvalue = userstore.state.draftanswers[i].answer_integer;
        }
      }
    }


    if (this.$route.query.alert) {
      this.snackbarText = this.$route.query.alert;
      //                  this.showSnackbar=true;
    }
  },

}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.stepper {}
</style>
