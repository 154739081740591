<template>
  <div class="hello">
    <div id="homecard"  class="md-card md-theme-demo-light">
        <img  id="bd4qicon" src="@/assets/bd4qol-logo.jpg"/>
        <div class="choicediv">
        <a href="/login"><md-button  class="homeoption md-raised md-primary">Login</md-button></a>
        </div>
        <div class="choicediv">
        <a href="/register"><md-button  class="homeoption md-raised md-primary">Register</md-button></a>
        </div>
        <img  id="dotsofticon" src="@/assets/dotsoft.png"/>
     </div>
  </div>
</template>
<script>
/*@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');*/

export default {
  name: 'Select',
 data () {
    return {
     
    }
  },
  methods: {
    
  },
  created: function(){

  },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
