<template>
  <div class="slider">

    <md-steppers :md-active-step.sync="horizontalactive" md-horizontal md-linear>
      <md-step id="first" :md-label="currentgeneralstrings[9]">

      </md-step>

      <md-step id="second" :md-label="currentgeneralstrings[10]">

      </md-step>

      <md-step id="third" :md-label="currentgeneralstrings[11]">

      </md-step>

      <md-step id="fourth" :md-label="currentgeneralstrings[12]">

      </md-step>

      <md-step id="fifth" :md-label="currentgeneralstrings[13]">

      </md-step>
    </md-steppers>

    <div class="slides">
      <div id="slide-29">
	<h2 v-if="lang == 'en'">For the following questions please select the number between 1 and 7 that best applies to you</h2>
        <h2 v-if="lang == 'it'">Per le seguenti domande selezioni un numero da 1 a 7 che meglio corrisponde alla Sua risposta</h2>
	{{ currenttextset[28] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value29" value="1">1<span>Very Poor</span></md-radio>
          <md-radio v-model="value29" value="2">2</md-radio>
          <md-radio v-model="value29" value="3">3</md-radio>
          <md-radio v-model="value29" value="4">4</md-radio>
          <md-radio v-model="value29" value="5">5</md-radio>
          <md-radio v-model="value29" value="6">6</md-radio>
          <md-radio v-model="value29" value="7">7<span>Excellent</span></md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value29" value="1">1<span>Pessima</span></md-radio>
          <md-radio v-model="value29" value="2">2</md-radio>
          <md-radio v-model="value29" value="3">3</md-radio>
          <md-radio v-model="value29" value="4">4</md-radio>
          <md-radio v-model="value29" value="5">5</md-radio>
          <md-radio v-model="value29" value="6">6</md-radio>
          <md-radio v-model="value29" value="7">7<span>Ottima</span></md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-30" v-on:click="setStep(2)">{{
              currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-30">
	<h2 v-if="lang == 'en'">For the following questions please select the number between 1 and 7 that best applies to you</h2>
        <h2 v-if="lang == 'it'">Per le seguenti domande selezioni un numero da 1 a 7 che meglio corrisponde alla Sua risposta</h2>
        {{ currenttextset[29] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value30" value="1">1<span>Very Poor</span></md-radio>
          <md-radio v-model="value30" value="2">2</md-radio>
          <md-radio v-model="value30" value="3">3</md-radio>
          <md-radio v-model="value30" value="4">4</md-radio>
          <md-radio v-model="value30" value="5">5</md-radio>
          <md-radio v-model="value30" value="6">6</md-radio>
          <md-radio v-model="value30" value="7">7<span>Excellent</span></md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value30" value="1">1<span>Pessima</span></md-radio>
          <md-radio v-model="value30" value="2">2</md-radio>
          <md-radio v-model="value30" value="3">3</md-radio>
          <md-radio v-model="value30" value="4">4</md-radio>
          <md-radio v-model="value30" value="5">5</md-radio>
          <md-radio v-model="value30" value="6">6</md-radio>
          <md-radio v-model="value30" value="7">7<span>Ottima</span></md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-29" v-on:click="setStep(1)">{{
              currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-1" v-on:click="setStep(3)">{{ currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-1">
	<h2 v-if="lang == 'it'">Con questo questionario vorremmo sapere alcune cose su di Lei e sulla Sua salute. La preghiamo di rispondere a tutte le domande selezionando l’opzione che meglio corrisponde alla Sua risposta. Non esiste una risposta "giusta" o "sbagliata". Le Sue informazioni verranno tenute strettamente riservate</h2>
        {{ currenttextset[0] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value1" value="1">Not at All</md-radio>
          <md-radio v-model="value1" value="2">A Little</md-radio>
          <md-radio v-model="value1" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value1" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value1" value="1">No</md-radio>
          <md-radio v-model="value1" value="2">Un po'</md-radio>
          <md-radio v-model="value1" value="3">Parecchio</md-radio>
          <md-radio v-model="value1" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-30" v-on:click="setStep(2)">{{
              currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-2" v-on:click="setStep(4)">{{ currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-2">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[1] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value2" value="1">Not at All</md-radio>
          <md-radio v-model="value2" value="2">A Little</md-radio>
          <md-radio v-model="value2" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value2" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value2" value="1">No</md-radio>
          <md-radio v-model="value2" value="2">Un po'</md-radio>
          <md-radio v-model="value2" value="3">Parecchio</md-radio>
          <md-radio v-model="value2" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-1" v-on:click="setStep(3)">{{ currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-3" v-on:click="setStep(5)">{{ currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-3">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[2] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value3" value="1">Not at All</md-radio>
          <md-radio v-model="value3" value="2">A Little</md-radio>
          <md-radio v-model="value3" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value3" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value3" value="1">No</md-radio>
          <md-radio v-model="value3" value="2">Un po'</md-radio>
          <md-radio v-model="value3" value="3">Parecchio</md-radio>
          <md-radio v-model="value3" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-2" v-on:click="setStep(4)">{{ currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-4" v-on:click="setStep(6)">{{ currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-4">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[3] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value4" value="1">Not at All</md-radio>
          <md-radio v-model="value4" value="2">A Little</md-radio>
          <md-radio v-model="value4" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value4" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value4" value="1">No</md-radio>
          <md-radio v-model="value4" value="2">Un po'</md-radio>
          <md-radio v-model="value4" value="3">Parecchio</md-radio>
          <md-radio v-model="value4" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-3" v-on:click="setStep(5)">{{ currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-5" v-on:click="setStep(7)">{{ currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-5">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[4] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value5" value="1">Not at All</md-radio>
          <md-radio v-model="value5" value="2">A Little</md-radio>
          <md-radio v-model="value5" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value5" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value5" value="1">No</md-radio>
          <md-radio v-model="value5" value="2">Un po'</md-radio>
          <md-radio v-model="value5" value="3">Parecchio</md-radio>
          <md-radio v-model="value5" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-4" v-on:click="setStep(6)">{{ currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-6" v-on:click="setStep(8)">{{ currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-6">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[5] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value6" value="1">Not at All</md-radio>
          <md-radio v-model="value6" value="2">A Little</md-radio>
          <md-radio v-model="value6" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value6" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value6" value="1">No</md-radio>
          <md-radio v-model="value6" value="2">Un po'</md-radio>
          <md-radio v-model="value6" value="3">Parecchio</md-radio>
          <md-radio v-model="value6" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-5" v-on:click="setStep(7)">{{ currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-7" v-on:click="setStep(9)">{{ currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-7">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[6] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value7" value="1">Not at All</md-radio>
          <md-radio v-model="value7" value="2">A Little</md-radio>
          <md-radio v-model="value7" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value7" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value7" value="1">No</md-radio>
          <md-radio v-model="value7" value="2">Un po'</md-radio>
          <md-radio v-model="value7" value="3">Parecchio</md-radio>
          <md-radio v-model="value7" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-6" v-on:click="setStep(8)">{{ currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-8" v-on:click="setStep(10)">{{
              currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-8">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[7] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value8" value="1">Not at All</md-radio>
          <md-radio v-model="value8" value="2">A Little</md-radio>
          <md-radio v-model="value8" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value8" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value8" value="1">No</md-radio>
          <md-radio v-model="value8" value="2">Un po'</md-radio>
          <md-radio v-model="value8" value="3">Parecchio</md-radio>
          <md-radio v-model="value8" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-7" v-on:click="setStep(9)">{{ currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-9" v-on:click="setStep(11)">{{
              currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-9">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[8] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value9" value="1">Not at All</md-radio>
          <md-radio v-model="value9" value="2">A Little</md-radio>
          <md-radio v-model="value9" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value9" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value9" value="1">No</md-radio>
          <md-radio v-model="value9" value="2">Un po'</md-radio>
          <md-radio v-model="value9" value="3">Parecchio</md-radio>
          <md-radio v-model="value9" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-8" v-on:click="setStep(10)">{{
              currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-10" v-on:click="setStep(12)">{{
              currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-10">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[9] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value10" value="1">Not at All</md-radio>
          <md-radio v-model="value10" value="2">A Little</md-radio>
          <md-radio v-model="value10" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value10" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value10" value="1">No</md-radio>
          <md-radio v-model="value10" value="2">Un po'</md-radio>
          <md-radio v-model="value10" value="3">Parecchio</md-radio>
          <md-radio v-model="value10" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-9" v-on:click="setStep(11)">{{
              currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-11" v-on:click="setStep(13)">{{
              currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-11">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[10] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value11" value="1">Not at All</md-radio>
          <md-radio v-model="value11" value="2">A Little</md-radio>
          <md-radio v-model="value11" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value11" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value11" value="1">No</md-radio>
          <md-radio v-model="value11" value="2">Un po'</md-radio>
          <md-radio v-model="value11" value="3">Parecchio</md-radio>
          <md-radio v-model="value11" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-10" v-on:click="setStep(12)">{{
              currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-12" v-on:click="setStep(14)">{{
              currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-12">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[11] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value12" value="1">Not at All</md-radio>
          <md-radio v-model="value12" value="2">A Little</md-radio>
          <md-radio v-model="value12" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value12" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value12" value="1">No</md-radio>
          <md-radio v-model="value12" value="2">Un po'</md-radio>
          <md-radio v-model="value12" value="3">Parecchio</md-radio>
          <md-radio v-model="value12" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-11" v-on:click="setStep(13)">{{
              currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-13" v-on:click="setStep(15)">{{
              currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-13">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[12] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value13" value="1">Not at All</md-radio>
          <md-radio v-model="value13" value="2">A Little</md-radio>
          <md-radio v-model="value13" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value13" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value13" value="1">No</md-radio>
          <md-radio v-model="value13" value="2">Un po'</md-radio>
          <md-radio v-model="value13" value="3">Parecchio</md-radio>
          <md-radio v-model="value13" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-12" v-on:click="setStep(14)">{{
              currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-14" v-on:click="setStep(16)">{{
              currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-14">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[13] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value14" value="1">Not at All</md-radio>
          <md-radio v-model="value14" value="2">A Little</md-radio>
          <md-radio v-model="value14" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value14" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value14" value="1">No</md-radio>
          <md-radio v-model="value14" value="2">Un po'</md-radio>
          <md-radio v-model="value14" value="3">Parecchio</md-radio>
          <md-radio v-model="value14" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-13" v-on:click="setStep(15)">{{
              currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-15" v-on:click="setStep(17)">{{
              currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-15">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[14] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value15" value="1">Not at All</md-radio>
          <md-radio v-model="value15" value="2">A Little</md-radio>
          <md-radio v-model="value15" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value15" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value15" value="1">No</md-radio>
          <md-radio v-model="value15" value="2">Un po'</md-radio>
          <md-radio v-model="value15" value="3">Parecchio</md-radio>
          <md-radio v-model="value15" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-14" v-on:click="setStep(16)">{{
              currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-16" v-on:click="setStep(18)">{{
              currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-16">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[15] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value16" value="1">Not at All</md-radio>
          <md-radio v-model="value16" value="2">A Little</md-radio>
          <md-radio v-model="value16" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value16" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value16" value="1">No</md-radio>
          <md-radio v-model="value16" value="2">Un po'</md-radio>
          <md-radio v-model="value16" value="3">Parecchio</md-radio>
          <md-radio v-model="value16" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-15" v-on:click="setStep(17)">{{
              currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-17" v-on:click="setStep(19)">{{
              currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-17">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[16] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value17" value="1">Not at All</md-radio>
          <md-radio v-model="value17" value="2">A Little</md-radio>
          <md-radio v-model="value17" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value17" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value17" value="1">No</md-radio>
          <md-radio v-model="value17" value="2">Un po'</md-radio>
          <md-radio v-model="value17" value="3">Parecchio</md-radio>
          <md-radio v-model="value17" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-16" v-on:click="setStep(18)">{{
              currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-18" v-on:click="setStep(20)">{{
              currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-18">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[17] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value18" value="1">Not at All</md-radio>
          <md-radio v-model="value18" value="2">A Little</md-radio>
          <md-radio v-model="value18" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value18" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value18" value="1">No</md-radio>
          <md-radio v-model="value18" value="2">Un po'</md-radio>
          <md-radio v-model="value18" value="3">Parecchio</md-radio>
          <md-radio v-model="value18" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-17" v-on:click="setStep(19)">{{
              currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-19" v-on:click="setStep(21)">{{
              currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-19">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[18] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value19" value="1">Not at All</md-radio>
          <md-radio v-model="value19" value="2">A Little</md-radio>
          <md-radio v-model="value19" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value19" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value19" value="1">No</md-radio>
          <md-radio v-model="value19" value="2">Un po'</md-radio>
          <md-radio v-model="value19" value="3">Parecchio</md-radio>
          <md-radio v-model="value19" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-18" v-on:click="setStep(20)">{{
              currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-20" v-on:click="setStep(22)">{{
              currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-20">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[19] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value20" value="1">Not at All</md-radio>
          <md-radio v-model="value20" value="2">A Little</md-radio>
          <md-radio v-model="value20" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value20" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value20" value="1">No</md-radio>
          <md-radio v-model="value20" value="2">Un po'</md-radio>
          <md-radio v-model="value20" value="3">Parecchio</md-radio>
          <md-radio v-model="value20" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-19" v-on:click="setStep(21)">{{
              currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-21" v-on:click="setStep(23)">{{
              currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-21">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[20] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value21" value="1">Not at All</md-radio>
          <md-radio v-model="value21" value="2">A Little</md-radio>
          <md-radio v-model="value21" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value21" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value21" value="1">No</md-radio>
          <md-radio v-model="value21" value="2">Un po'</md-radio>
          <md-radio v-model="value21" value="3">Parecchio</md-radio>
          <md-radio v-model="value21" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-20" v-on:click="setStep(22)">{{
              currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-22" v-on:click="setStep(24)">{{
              currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-22">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[21] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value22" value="1">Not at All</md-radio>
          <md-radio v-model="value22" value="2">A Little</md-radio>
          <md-radio v-model="value22" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value22" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value22" value="1">No</md-radio>
          <md-radio v-model="value22" value="2">Un po'</md-radio>
          <md-radio v-model="value22" value="3">Parecchio</md-radio>
          <md-radio v-model="value22" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-21" v-on:click="setStep(23)">{{
              currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-23" v-on:click="setStep(25)">{{
              currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-23">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[22] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value23" value="1">Not at All</md-radio>
          <md-radio v-model="value23" value="2">A Little</md-radio>
          <md-radio v-model="value23" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value23" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value23" value="1">No</md-radio>
          <md-radio v-model="value23" value="2">Un po'</md-radio>
          <md-radio v-model="value23" value="3">Parecchio</md-radio>
          <md-radio v-model="value23" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-22" v-on:click="setStep(24)">{{
              currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-24" v-on:click="setStep(26)">{{
              currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-24">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[23] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value24" value="1">Not at All</md-radio>
          <md-radio v-model="value24" value="2">A Little</md-radio>
          <md-radio v-model="value24" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value24" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value24" value="1">No</md-radio>
          <md-radio v-model="value24" value="2">Un po'</md-radio>
          <md-radio v-model="value24" value="3">Parecchio</md-radio>
          <md-radio v-model="value24" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-23" v-on:click="setStep(25)">{{
              currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-25" v-on:click="setStep(27)">{{
              currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-25">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[24] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value25" value="1">Not at All</md-radio>
          <md-radio v-model="value25" value="2">A Little</md-radio>
          <md-radio v-model="value25" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value25" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value25" value="1">No</md-radio>
          <md-radio v-model="value25" value="2">Un po'</md-radio>
          <md-radio v-model="value25" value="3">Parecchio</md-radio>
          <md-radio v-model="value25" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-24" v-on:click="setStep(26)">{{
              currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-26" v-on:click="setStep(28)">{{
              currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-26">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[25] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value26" value="1">Not at All</md-radio>
          <md-radio v-model="value26" value="2">A Little</md-radio>
          <md-radio v-model="value26" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value26" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value26" value="1">No</md-radio>
          <md-radio v-model="value26" value="2">Un po'</md-radio>
          <md-radio v-model="value26" value="3">Parecchio</md-radio>
          <md-radio v-model="value26" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-25" v-on:click="setStep(27)">{{
              currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-27" v-on:click="setStep(29)">{{
              currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-27">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[26] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value27" value="1">Not at All</md-radio>
          <md-radio v-model="value27" value="2">A Little</md-radio>
          <md-radio v-model="value27" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value27" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value27" value="1">No</md-radio>
          <md-radio v-model="value27" value="2">Un po'</md-radio>
          <md-radio v-model="value27" value="3">Parecchio</md-radio>
          <md-radio v-model="value27" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-26" v-on:click="setStep(28)">{{
              currentgeneralstrings[1]
          }}</a></md-button>
          <md-button class="md-raised md-primary"><a href="#slide-28" v-on:click="setStep(30)">{{
              currentgeneralstrings[0]
          }}</a></md-button>
        </div>
      </div>
      <div id="slide-28">
	<h2 v-if="lang == 'it'">Durante gli ultimi sette giorni</h2>
        {{ currenttextset[27] }}
        <div v-if="lang == 'en'" class="radios">
          <md-radio v-model="value28" value="1">Not at All</md-radio>
          <md-radio v-model="value28" value="2">A Little</md-radio>
          <md-radio v-model="value28" value="3">Quite a Bit</md-radio>
          <md-radio v-model="value28" value="4">Very Much</md-radio>
        </div>
        <div v-if="lang == 'it'" class="radios">
          <md-radio v-model="value28" value="1">No</md-radio>
          <md-radio v-model="value28" value="2">Un po'</md-radio>
          <md-radio v-model="value28" value="3">Parecchio</md-radio>
          <md-radio v-model="value28" value="4">Moltissimo</md-radio>
        </div>
        <div class="actions">
          <md-button class="md-raised md-primary"><a href="#slide-27" v-on:click="setStep(29)">{{
              currentgeneralstrings[1]
          }}</a></md-button>
        </div>
      </div>
    </div>

    <div class="slider-anchors">
      <a v-if="(qlqstep == 1)" class="selectedanchor" href="#slide-29" v-on:click="setStep(1)">1</a>
      <a v-else href="#slide-29" v-on:click="setStep(1)">1</a>
      <a v-if="(qlqstep == 2)" class="selectedanchor" href="#slide-30" v-on:click="setStep(2)">2</a>
      <a v-else href="#slide-30" v-on:click="setStep(2)">2</a>

      <a v-if="(qlqstep == 3)" class="selectedanchor" href="#slide-1" v-on:click="setStep(3)">3</a>
      <a v-else href="#slide-1" v-on:click="setStep(3)">3</a>
      <a v-if="(qlqstep == 4)" class="selectedanchor" href="#slide-2" v-on:click="setStep(4)">4</a>
      <a v-else href="#slide-2" v-on:click="setStep(4)">4</a>
      <a v-if="(qlqstep == 5)" class="selectedanchor" href="#slide-3" v-on:click="setStep(5)">5</a>
      <a v-else href="#slide-3" v-on:click="setStep(5)">5</a>
      <a v-if="(qlqstep == 6)" class="selectedanchor" href="#slide-4" v-on:click="setStep(6)">6</a>
      <a v-else href="#slide-4" v-on:click="setStep(6)">6</a>
      <a v-if="(qlqstep == 7)" class="selectedanchor" href="#slide-5" v-on:click="setStep(7)">7</a>
      <a v-else href="#slide-5" v-on:click="setStep(7)">7</a>
      <a v-if="(qlqstep == 8)" class="selectedanchor" href="#slide-6" v-on:click="setStep(8)">8</a>
      <a v-else href="#slide-6" v-on:click="setStep(8)">8</a>
      <a v-if="(qlqstep == 9)" class="selectedanchor" href="#slide-7" v-on:click="setStep(9)">9</a>
      <a v-else href="#slide-7" v-on:click="setStep(9)">9</a>
      <a v-if="(qlqstep == 10)" class="selectedanchor" href="#slide-8" v-on:click="setStep(10)">10</a>
      <a v-else href="#slide-8" v-on:click="setStep(10)">10</a>
      <a v-if="(qlqstep == 11)" class="selectedanchor" href="#slide-9" v-on:click="setStep(11)">11</a>
      <a v-else href="#slide-9" v-on:click="setStep(11)">11</a>
      <a v-if="(qlqstep == 12)" class="selectedanchor" href="#slide-10" v-on:click="setStep(12)">12</a>
      <a v-else href="#slide-10" v-on:click="setStep(12)">12</a>

      <a v-if="(qlqstep == 13)" class="selectedanchor" href="#slide-11" v-on:click="setStep(13)">13</a>
      <a v-else href="#slide-11" v-on:click="setStep(13)">13</a>
      <a v-if="(qlqstep == 14)" class="selectedanchor" href="#slide-12" v-on:click="setStep(14)">14</a>
      <a v-else href="#slide-12" v-on:click="setStep(14)">14</a>
      <a v-if="(qlqstep == 15)" class="selectedanchor" href="#slide-13" v-on:click="setStep(15)">15</a>
      <a v-else href="#slide-13" v-on:click="setStep(15)">15</a>
      <a v-if="(qlqstep == 16)" class="selectedanchor" href="#slide-14" v-on:click="setStep(16)">16</a>
      <a v-else href="#slide-14" v-on:click="setStep(16)">16</a>
      <a v-if="(qlqstep == 17)" class="selectedanchor" href="#slide-15" v-on:click="setStep(17)">17</a>
      <a v-else href="#slide-15" v-on:click="setStep(17)">17</a>
      <a v-if="(qlqstep == 18)" class="selectedanchor" href="#slide-16" v-on:click="setStep(18)">18</a>
      <a v-else href="#slide-16" v-on:click="setStep(18)">18</a>
      <a v-if="(qlqstep == 19)" class="selectedanchor" href="#slide-17" v-on:click="setStep(19)">19</a>
      <a v-else href="#slide-17" v-on:click="setStep(19)">19</a>
      <a v-if="(qlqstep == 20)" class="selectedanchor" href="#slide-18" v-on:click="setStep(20)">20</a>
      <a v-else href="#slide-18" v-on:click="setStep(20)">20</a>
      <a v-if="(qlqstep == 21)" class="selectedanchor" href="#slide-19" v-on:click="setStep(21)">21</a>
      <a v-else href="#slide-19" v-on:click="setStep(21)">21</a>
      <a v-if="(qlqstep == 22)" class="selectedanchor" href="#slide-20" v-on:click="setStep(22)">22</a>
      <a v-else href="#slide-20" v-on:click="setStep(22)">22</a>

      <a v-if="(qlqstep == 23)" class="selectedanchor" href="#slide-21" v-on:click="setStep(23)">23</a>
      <a v-else href="#slide-21" v-on:click="setStep(23)">23</a>
      <a v-if="(qlqstep == 24)" class="selectedanchor" href="#slide-22" v-on:click="setStep(24)">24</a>
      <a v-else href="#slide-22" v-on:click="setStep(24)">24</a>
      <a v-if="(qlqstep == 25)" class="selectedanchor" href="#slide-23" v-on:click="setStep(25)">25</a>
      <a v-else href="#slide-23" v-on:click="setStep(25)">25</a>
      <a v-if="(qlqstep == 26)" class="selectedanchor" href="#slide-24" v-on:click="setStep(26)">26</a>
      <a v-else href="#slide-24" v-on:click="setStep(26)">26</a>
      <a v-if="(qlqstep == 27)" class="selectedanchor" href="#slide-25" v-on:click="setStep(27)">27</a>
      <a v-else href="#slide-25" v-on:click="setStep(27)">27</a>
      <a v-if="(qlqstep == 28)" class="selectedanchor" href="#slide-26" v-on:click="setStep(28)">28</a>
      <a v-else href="#slide-26" v-on:click="setStep(28)">28</a>
      <a v-if="(qlqstep == 29)" class="selectedanchor" href="#slide-27" v-on:click="setStep(29)">29</a>
      <a v-else href="#slide-27" v-on:click="setStep(29)">29</a>
      <a v-if="(qlqstep == 30)" class="selectedanchor" href="#slide-28" v-on:click="setStep(30)">30</a>
      <a v-else href="#slide-28" v-on:click="setStep(30)">30</a>

    </div>

    <div class="action-container">

      <FormulateInput id="saveforlater" v-on:click="saveForLater" type="submit" :label="currentgeneralstrings[4]" />

      <FormulateInput id="submit" v-on:click="answerSubmit" type="submit" :label="currentgeneralstrings[3]" />


      <div class="spinner">
        <bounce v-if="isLoading"></bounce>
      </div>

    </div>
  </div>
</template>
<script>
/*@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');*/
import { userstore } from '../data/userstore.js';
import './sliderstyle.css'

export default {
  name: 'StepperNonEditable',
  data() {
    return {
      datasent: 0,
      result: 0,
      step: 1,
      //     answers:{},
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      value5: "",
      value6: "",
      value7: "",
      value8: "",
      value9: "",
      value10: "",
      value11: "",
      value12: "",
      value13: "",
      value14: "",
      value15: "",
      value16: "",
      value17: "",
      value18: "",
      value19: "",
      value20: "",
      value21: "",
      value22: "",
      value23: "",
      value24: "",
      value25: "",
      value26: "",
      value27: "",
      value28: "",
      value29: "",
      value30: "",
      value: [],
      publicPath: process.env.BASE_URL,
      active: 'one',
      horizontalactive: 'first',
      one: true,
      two: true,
      three: true,
      four: true,
      five: true,
      six: true,
      seven: true,
      eight: true,
      nine: true,
      ten: true,
      eleven: true,
      twelve: true,
      thirteen: true,
      fourteen: true,
      fifteen: true,
      sixteen: true,
      seventeen: true,
      eighteen: true,
      nineteen: true,
      twenty: true,
      twentyone: true,
      twentytwo: true,
      twentythree: true,
      twentyfour: true,
      twentyfive: true,
      twentysix: true,
      twentyseven: true,
      twentyeight: true,
      twentynine: true,
      thirty: true,
      StepOneError: null,
      StepTwoError: null,
      StepThreeError: null,
      StepFourError: null,
      StepFiveError: null,
      StepSixError: null,
      StepSevenError: null,
      StepEightError: null,
      StepNineError: null,
      StepTenError: null,
      StepElevenError: null,
      StepTwelveError: null,
      StepThirteenError: null,
      StepFourteenError: null,
      StepFifteenError: null,
      StepSixteenError: null,
      StepSeventeenError: null,
      StepEighteenError: null,
      StepNineteenError: null,
      StepTwentyError: null,
      StepTwentyoneError: null,
      StepTwentytwoError: null,
      StepTwentythreeError: null,
      StepTwentyfourError: null,
      StepTwentyfiveError: null,
      StepTwentysixError: null,
      StepTwentysevenError: null,
      StepTwentyeightError: null,
      StepTwentynineError: null,
      StepThirtyError: null,
      showSnackbar: false,
      position: 'center',
      duration: 4000,
      isInfinity: false,
      snackbarText: 'Submission error',
      showAlert1: false,
      showAlert2: false,
      showAlert3: false,
      qlqstep: 1,
      isdraft: 0,
      isLoading: false,
      lang: userstore.state.lang,
      questionoptionsen: [
        { 1: 'Not at All', 2: 'A Little', 3: 'Quite a Bit', 4: 'Very Much' },
        { 1: 'Very Poor', 2: 'Excellent' }
      ],
      questionoptionsit: [
        { 1: 'No', 2: 'Un po\'', 3: 'Parechio', 4: 'Moltissimo' },
        { 1: 'Pessima', 2: 'Ottima' }
      ],
      questiontexten: [
        "Question 3: Do you have any trouble doing strenuous activities, like carrying a heavy shopping bag or a suitcase?",
        "Question 4: Do you have any trouble taking a long walk?",
        "Question 5: Do you have any trouble taking a short walk outside of the house?",
        "Question 6: Do you need to stay in bed or a chair during the day?",
        "Question 7: Do you need help with eating, dressing, washing yourself or using the toilet?",
        "Question 8: Were you limited in doing either your work or other daily activities?",
        "Question 9: Were you limited in pursuing your hobbies or other leisure time activities?",
        "Question 10: Were you short of breath?",
        "Question 11: Have you had pain?",
        "Question 12: Did you need to rest?",
        "Question 13: Have you had trouble sleeping?",
        "Question 14: Have you feld weak?",
        "Question 15: Have you lacked appetite?",
        "Question 16: Have you felt nauseated?",
        "Question 17: Have you vomited?",
        "Question 18: Have you been constipated?",
        "Question 19: Have you had diarrhea?",
        "Question 20: Were you tired?",
        "Question 21: Did pain interfere with your daily activities?",
        "Question 22: Have you had difficulty in concentrating on things, like reading a newspaper or watching television?",
        "Question 23: Did you feel tense?",
        "Question 24: Did you worry?",
        "Question 25: Did you feel irritable?",
        "Question 26: Did you feel depressed?",
        "Question 27: Have you had difficulty remembering things?",
        "Question 28: Has your physical condition or medical treatment interfered with your family life?",
        "Question 29: Has your physical condition or medical treatment interfered with your social activities?",
        "Question 30: Has your physical condition or medical treatment caused you financial difficulties?",
        "Question 1: How would you rate your overall health during the past week?",
        "Question 2: How would you rate your overall quality of life during the past week?"],
      questiontextit: [
        "Domanda 3: Ha difficoltà nel fare lavori faticosi, come sollevare una borsa della spesa pesante o una valigia?",
        "Domanda 4: Ha difficoltà nel fare una lunga passeggiata?",
        "Domanda 5: Ha difficoltà nel fare una breve passeggiata fuori casa?",
        "Domanda 6: Ha bisogno di stare a letto o su una sedia durante il giorno?",
        "Domanda 7: Ha bisogno di aiuto per mangiare, vestirsi, lavarsi o andare in bagno?",
        "Domanda 8: Ha avuto limitazioni nel fare il Suo lavoro o i lavori di casa?",
        "Domanda 9: Ha avuto limitazioni nel praticare i Suoi passatempi-hobby o altre attività di divertimento o svago?",
        "Domanda 10: Le è mancato il fiato?",
        "Domanda 11: Ha avuto dolore?",
        "Domanda 12: Ha avuto bisogno di riposo?",
        "Domanda 13: Ha avuto difficoltà a dormire?",
        "Domanda 14: Ha sentito debolezza?",
        "Domanda 15: Le è mancato l'appetito?",
        "Domanda 16: Ha avuto un senso di nausea?",
        "Domanda 17: Ha vomitato?",
        "Domanda 18: Ha avuto problemi di stitichezza?",
        "Domanda 19: Ha avuto problemi di diarrea?",
        "Domanda 20: Ha sentito stanchezza?",
        "Domanda 21: Il dolore ha interferito con le Sue attività quotidiane?",
        "Domanda 22: Ha avuto difficoltà a concentrarsi su cose come leggere un giornale o guardare la televisione?",
        "Domanda 23: Si è sentito(a) teso(a)?",
        "Domanda 24: Ha avuto preoccupazioni?",
        "Domanda 25: Ha avuto manifestazioni di irritabilità? ",
        "Domanda 26: Ha avvertito uno stato di depressione?",
        "Domanda 27: Ha avuto difficoltà a ricordare le cose?",
        "Domanda 28: Le Sue condizioni fisiche o il Suo trattamento medico hanno interferito con la Sua vita familiare?",
        "Domanda 29: Le Sue condizioni fisiche o il Suo trattamento medico hanno interferito con le Sue attività sociali?",
        "Domanda 30: Le Sue condizioni fisiche o il Suo trattamento medico Le hanno causato difficoltà finanziarie?",
        "Domanda 1: Come valuterebbe in generale la Sua salute durante gli ultimi sette giorni? ",
        "Domanda 2: Come valuterebbe in generale la Sua qualità di vita durante gli ultimi sette giorni?"],
      generalstringsen: [
        "Next question",
        "Previous question",
        "Please answer question ",
        "Submit",
        "Save for later",
        "Cancel",
        "Alert",
        "Please note that some questions have not been answered. Press 'Submit' to skip these questions or 'Cancel' to continue answering.",
        "Please evaluate your overall health",
        "Quality of Life",
        "Head & Neck Cancer",
        "Health Questionnaire",
        "Cancer Behaviour Inventory",
        "Care received",
      ],
      generalstringsit: [
        "Prossima domanda",
        "Domanda precedente",
        "Per favore rispondi alla domanda",
        "Invia",
        "Salva per dopo",
        "Annulla",
        "Notifica",
        'Attenzione, non hai risposto ad alcune domande. Premi "Invia" per saltare queste domande oppure "Annulla" per continuare a rispondere.',
        "Per favore, valuta la tua salute",
        "EORTC QLQ-C30",
        "EORTC QLQ-H&N43",
        "EQ-5D-5L",
        "CBI-B",
        "PREM",
      ],
      currentoptionset: this.questionoptionsen,
      currenttextset: this.questiontexten,
      currentgeneralstrings: this.generalstringsen,
      currentwording: this.wordingen,
    }
  },
  methods: {
    setDone(id, index) {
      this[id] = true

      //  this.secondStepError = null

      if (index) {
        this.active = index
      }
    },
    saveForLater() {
      //save for later api call
      this.isdraft = 1;
      this.postAnswers();
    },
    answerSubmit() {
      this.postAnswers();
      // this.$router.push("/head-and-neck-cancer?alert="+"result: success");
    },
    setStep(step) {
      /* for(var i=0;i<30;i++){
         console.log('#slide-'+i);
         document.querySelector('#slide-'+i).classList.remove('selectedstep');
       }*/

      this.qlqstep = step;
    },
    currentDateGreek() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      today = yyyy + '-' + mm + '-' + dd;
      return today;
    },
    postAnswers() {
      this.isLoading = true;
      var QL2, PF2, RF2, EF, CF, SF, PA, DY, SL, AP, CO, DI, FI, FA, NV;
      //     var setsanswered=13;
      var emptycounter = 0;
      //Computing Score
      var questionable = [];

      if(this.value29!='' && this.value30!=''){
        QL2 = (Number(this.value29) + Number(this.value30)) / 2;
        QL2 = ((QL2 - 1) / 6) * 100;
      }
      else if(this.value29!=''){
        QL2 = (Number(this.value29));
        QL2 = ((QL2 - 1) / 6) * 100;
      }
      else if(this.value30!=''){
        QL2 = (Number(this.value30));
        QL2 = ((QL2 - 1) / 6) * 100;
      }
      else{
        QL2 = -1;
      } 
      
      //QL2 = (Number(this.value29) + Number(this.value30)) / 2;
      //QL2 = ((QL2 - 1) / 6) * 100;
      //QL2 = 0;  //not used

      //PF2
      emptycounter = 0;
      if (this.value1 == '') {
        emptycounter++;
      }
      if (this.value2 == '') {
        emptycounter++;
      }
      if (this.value3 == '') {
        emptycounter++;
      }
      if (this.value4 == '') {
        emptycounter++;
      }
      if (this.value5 == '') {
        emptycounter++;
      }
      if (emptycounter < 3) {
        PF2 = (Number(this.value1) + Number(this.value2) + Number(this.value3) + Number(this.value4) + Number(this.value5)) / (5 - emptycounter);
        PF2 = (1 - ((PF2 - 1) / 3).toFixed(4)) * 100;
      }
      else {
        PF2 = -1;
        //    setsanswered--;
      }


      //RF2
      emptycounter = 0;
      if (this.value6 == '') {
        emptycounter++;
      }
      if (this.value7 == '') {
        emptycounter++;
      }
      if (emptycounter < 2) {
        RF2 = (Number(this.value6) + Number(this.value7)) / (2 - emptycounter);
        RF2 = (1 - ((RF2 - 1) / 3).toFixed(4)) * 100;
      }
      else {
        RF2 = -1;
        //   setsanswered--;
      }

      //EF
      emptycounter = 0;
      if (this.value21 == '') {
        emptycounter++;
      }
      if (this.value22 == '') {
        emptycounter++;
      }
      if (this.value23 == '') {
        emptycounter++;
      }
      if (this.value24 == '') {
        emptycounter++;
      }
      if (emptycounter < 2) {
        EF = (Number(this.value21) + Number(this.value22) + Number(this.value23) + Number(this.value24)) / (4 - emptycounter);
        EF = (1 - ((EF - 1) / 3).toFixed(4)) * 100;
      }
      else {
        EF = -1;
        //    setsanswered--;
      }


      //CF
      emptycounter = 0;
      if (this.value20 == '') {
        emptycounter++;
      }
      if (this.value25 == '') {
        emptycounter++;
      }
      if (emptycounter < 2) {
        CF = (Number(this.value20) + Number(this.value25)) / (2 - emptycounter);
        CF = (1 - ((CF - 1) / 3).toFixed(4)) * 100;
      }
      else {
        CF = -1;
        //     setsanswered--;
      }


      //SF
      emptycounter = 0;
      if (this.value26 == '') {
        emptycounter++;
      }
      if (this.value27 == '') {
        emptycounter++;
      }
      if (emptycounter < 2) {
        SF = (Number(this.value26) + Number(this.value27)) / (2 - emptycounter);
        SF = (1 - ((SF - 1) / 3).toFixed(4)) * 100;
      }
      else {
        SF = -1;
        //    setsanswered--;
      }


      //FA
      emptycounter = 0;
      if (this.value10 == '') {
        emptycounter++;
      }
      if (this.value12 == '') {
        emptycounter++;
      }
      if (this.value18 == '') {
        emptycounter++;
      }
      if (emptycounter < 2) {
        FA = (Number(this.value10) + Number(this.value12) + Number(this.value18)) / (3 - emptycounter);
        FA = (((FA - 1) / 3).toFixed(4)) * 100;
      }
      else {
        FA = -1;
        //    setsanswered--;
      }


      //NV
      emptycounter = 0;
      if (this.value14 == '') {
        emptycounter++;
      }
      if (this.value15 == '') {
        emptycounter++;
      }
      if (emptycounter < 2) {
        NV = (Number(this.value14) + Number(this.value15)) / (2 - emptycounter);
        NV = (((NV - 1) / 3).toFixed(4)) * 100;
      }
      else {
        //     setsanswered--;
        NV = -1;
      }


      //PA
      emptycounter = 0;
      if (this.value9 == '') {
        emptycounter++;
      }
      if (this.value19 == '') {
        emptycounter++;
      }
      if (emptycounter < 2) {
        PA = (Number(this.value9) + Number(this.value19)) / (2 - emptycounter);
        PA = (((PA - 1) / 3).toFixed(4)) * 100;
      }
      else {
        //    setsanswered--;
        PA = -1;
      }


      //DY
      if (this.value8 != '') {
        DY = Number(this.value8);
        DY = (((DY - 1) / 3).toFixed(4)) * 100;
      }
      else {
        DY = -1;
        //    setsanswered--;
      }

      //SL
      if (this.value11 != '') {
        SL = Number(this.value11);
        SL = (((SL - 1) / 3).toFixed(4)) * 100;
      }
      else {
        SL = -1;
        //     setsanswered--;
      }

      //AP
      if (this.value13 != '') {
        AP = Number(this.value13);
        AP = (((AP - 1) / 3).toFixed(4)) * 100;
      }
      else {
        AP = -1;
        //    setsanswered--;
      }

      //CO
      if (this.value16 != '') {
        CO = Number(this.value16);
        CO = (((CO - 1) / 3).toFixed(4)) * 100;
      }
      else {
        CO = -1;
        //     setsanswered--;
      }


      //DI
      if (this.value17 != '') {
        DI = Number(this.value17);
        DI = (((DI - 1) / 3).toFixed(4)) * 100;
      }
      else {
        DI = -1;
        //    setsanswered--;
      }

      //FI
      if (this.value28 != '') {
        FI = Number(this.value28);
        FI = (((FI - 1) / 3).toFixed(4)) * 100;
      }
      else {
        FI = -1;
        //    setsanswered--;
      }

      // this.result=((QL2+PF2+RF2+EF+CF+SF+(100-PA)+(100-DY)+(100-SL)+(100-AP)+(100-CO)+(100-DI)+(100-FI)+(100-FA)+(100-NV))/setsanswered).toFixed(4);

      if (this.datasent == 0) {
        this.datasent = 1;
        var answers = [
          {
            "question_id": 1,
            "answer_integer": this.value1,
            "draft": this.isdraft
          },
          {
            "question_id": 2,
            "answer_integer": this.value2,
            "draft": this.isdraft
          },
          {
            "question_id": 3,
            "answer_integer": this.value3,
            "draft": this.isdraft
          },
          {
            "question_id": 4,
            "answer_integer": this.value4,
            "draft": this.isdraft
          },
          {
            "question_id": 5,
            "answer_integer": this.value5,
            "draft": this.isdraft
          },
          {
            "question_id": 6,
            "answer_integer": this.value6,
            "draft": this.isdraft
          },
          {
            "question_id": 7,
            "answer_integer": this.value7,
            "draft": this.isdraft
          },
          {
            "question_id": 8,
            "answer_integer": this.value8,
            "draft": this.isdraft
          },
          {
            "question_id": 9,
            "answer_integer": this.value9,
            "draft": this.isdraft
          },
          {
            "question_id": 10,
            "answer_integer": this.value10,
            "draft": this.isdraft
          },
          {
            "question_id": 11,
            "answer_integer": this.value11,
            "draft": this.isdraft
          },
          {
            "question_id": 12,
            "answer_integer": this.value12,
            "draft": this.isdraft
          },
          {
            "question_id": 13,
            "answer_integer": this.value13,
            "draft": this.isdraft
          },
          {
            "question_id": 14,
            "answer_integer": this.value14,
            "draft": this.isdraft
          },
          {
            "question_id": 15,
            "answer_integer": this.value15,
            "draft": this.isdraft
          },
          {
            "question_id": 16,
            "answer_integer": this.value16,
            "draft": this.isdraft
          },
          {
            "question_id": 17,
            "answer_integer": this.value17,
            "draft": this.isdraft
          },
          {
            "question_id": 18,
            "answer_integer": this.value18,
            "draft": this.isdraft
          },
          {
            "question_id": 19,
            "answer_integer": this.value19,
            "draft": this.isdraft
          },
          {
            "question_id": 20,
            "answer_integer": this.value20,
            "draft": this.isdraft
          },
          {
            "question_id": 21,
            "answer_integer": this.value21,
            "draft": this.isdraft
          },
          {
            "question_id": 22,
            "answer_integer": this.value22,
            "draft": this.isdraft
          },
          {
            "question_id": 23,
            "answer_integer": this.value23,
            "draft": this.isdraft
          },
          {
            "question_id": 24,
            "answer_integer": this.value24,
            "draft": this.isdraft
          },
          {
            "question_id": 25,
            "answer_integer": this.value25,
            "draft": this.isdraft
          },
          {
            "question_id": 26,
            "answer_integer": this.value26,
            "draft": this.isdraft
          },
          {
            "question_id": 27,
            "answer_integer": this.value27,
            "draft": this.isdraft
          },
          {
            "question_id": 28,
            "answer_integer": this.value28,
            "draft": this.isdraft
          },
          {
            "question_id": 29,
            "answer_integer": this.value29,
            "draft": this.isdraft
          },
          {
            "question_id": 30,
            "answer_integer": this.value30,
            "draft": this.isdraft
          },
        ];

        console.log("QL2:" + QL2);
        console.log("PF:" + PF2);
        console.log("RF2:" + RF2);
        console.log("EF:" + EF);
        console.log("CD:" + CF);
        console.log("SF:" + SF);
        console.log("PA:" + Number.parseInt(100 - PA));
        console.log("DY:" + Number.parseInt(100 - DY));
        console.log("SL:" + Number.parseInt(100 - SL));
        console.log("AP:" + Number.parseInt(100 - AP));
        console.log("CO:" + Number.parseInt(100 - CO));
        console.log("DI:" + Number.parseInt(100 - DI));
        console.log("FI:" + Number.parseInt(100 - FI));
        console.log("FA:" + Number.parseInt(100 - FA));
        console.log("NV:" + Number.parseInt(100 - NV));
        //  console.log(this.result);

        console.log(userstore.state.firstName);
        questionable = userstore.state.currentQuestionnaires.filter((q) => {
          return q.questionable_type === 'quality_of_lives';
        });
        console.log("questionable - " + questionable[0].questionable_id);
        console.log("userstore.state.submissionDate - " + userstore.state.submissionDate);

        if (this.isdraft == 0) {
          //          console.log(answers);
          this.$http.post('https://' + userstore.state.backurl + '/api/questionnaires/qol', {
            "global_health_status": QL2,
            "physical_functioning": PF2,
            "role_functioning": RF2,
            "social_functioning": SF,
            "cognitive_functioning": CF,
            "emotional_functioning": EF,
            "fatigue": FA,
            "nausea_vomiting": NV,
            "pain": PA,
            "dyspnoea": DY,
            "insomnia": SL,
            "apettite_loss": AP,
            "constipation": CO,
            "diarrhoea": DI,
            "financial_difficulties": FI,
            "answers": answers,
            "study_id": userstore.state.firstName,
            "datetime": userstore.state.submissionDate,
            "draft": this.isdraft,
            "questionable_id": questionable[0].questionable_id,
            headers: {}
          })
            .then(function (response) {
              console.log(response);
              console.log('redirecting...');
              this.$router.push("/head-and-neck-cancer");
            }).catch(function (response) {
              if (response.status == 404 || response.status == 403) {
                console.log(response);

              }
              else {  //for testing while wating for api
                //         this.$router.push("/head-and-neck-cancer?alert="+"result:success");
              }
            }
            );
        }
        else {
          console.log('save for later');
          this.$http.post('https://' + userstore.state.backurl + '/api/questionnaires/qol', {
            "answers": answers,
            "study_id": userstore.state.firstName,
            "datetime": userstore.state.submissionDate,
            "draft": this.isdraft,
            "questionable_id": questionable[0].questionable_id,
            headers: {}
          })
            .then(function (response) {
              console.log(response);
              userstore.commit('changeId', null);
              console.log('userstore.state.isClinician - ' + userstore.state.isClinician);
              if (userstore.state.isClinician) {
                this.$router.push("/list-questionnaires");
              }
              else {
                this.$router.push("/login");
              }
              //       this.$router.push("/head-and-neck-cancer?alert="+"result:success ");
            }).catch(function (response) {
              if (response.status != 200) {
                console.log(response);

              }
            }
            );
        }
      }
    },

  },
  created: function () {
    console.log(userstore.state.currentQuestionnaires);
    var questionable = userstore.state.currentQuestionnaires.filter((q) => {
      return q.questionable_type === 'quality_of_lives';
    });
    console.log("questionable - " + JSON.stringify(questionable));

    window.scrollTo(0, 0);

    if (userstore.state.lang == "en") {
      this.currentoptionset = this.questionoptionsen;
      this.currenttextset = this.questiontexten;
      this.currentgeneralstrings = this.generalstringsen;
    }
    else if (userstore.state.lang == "it") {
      this.currentoptionset = this.questionoptionsit;
      this.currenttextset = this.questiontextit;
      this.currentgeneralstrings = this.generalstringsit;
    }


    console.log(userstore.state.draftanswers);
    if (userstore.state.draftquestionaire == "quality_of_lives") {
      for (var i = 0; i < userstore.state.draftanswers.length; i++) {
        if (userstore.state.draftanswers[i].question_id == 1 && userstore.state.draftanswers[i].answer_integer != -1) {
          this.value1 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 2 && userstore.state.draftanswers[i].answer_integer != -1) {
          this.value2 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 3 && userstore.state.draftanswers[i].answer_integer != -1) {
          this.value3 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 4 && userstore.state.draftanswers[i].answer_integer != -1) {
          this.value4 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 5 && userstore.state.draftanswers[i].answer_integer != -1) {
          this.value5 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 6 && userstore.state.draftanswers[i].answer_integer != -1) {
          this.value6 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 7 && userstore.state.draftanswers[i].answer_integer != -1) {
          this.value7 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 8 && userstore.state.draftanswers[i].answer_integer != -1) {
          this.value8 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 9 && userstore.state.draftanswers[i].answer_integer != -1) {

          this.value9 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 10 && userstore.state.draftanswers[i].answer_integer != -1) {

          this.value10 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 11 && userstore.state.draftanswers[i].answer_integer != -1) {

          this.value11 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 12 && userstore.state.draftanswers[i].answer_integer != -1) {

          this.value12 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 13 && userstore.state.draftanswers[i].answer_integer != -1) {

          this.value13 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 14 && userstore.state.draftanswers[i].answer_integer != -1) {

          this.value14 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 15 && userstore.state.draftanswers[i].answer_integer != -1) {

          this.value15 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 16 && userstore.state.draftanswers[i].answer_integer != -1) {

          this.value16 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 17 && userstore.state.draftanswers[i].answer_integer != -1) {

          this.value17 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 18 && userstore.state.draftanswers[i].answer_integer != -1) {

          this.value18 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 19 && userstore.state.draftanswers[i].answer_integer != -1) {

          this.value19 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 20 && userstore.state.draftanswers[i].answer_integer != -1) {

          this.value20 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 21 && userstore.state.draftanswers[i].answer_integer != -1) {

          this.value21 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 22 && userstore.state.draftanswers[i].answer_integer != -1) {

          this.value22 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 23 && userstore.state.draftanswers[i].answer_integer != -1) {

          this.value23 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 24 && userstore.state.draftanswers[i].answer_integer != -1) {

          this.value24 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 25 && userstore.state.draftanswers[i].answer_integer != -1) {

          this.value25 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 26 && userstore.state.draftanswers[i].answer_integer != -1) {

          this.value26 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 27 && userstore.state.draftanswers[i].answer_integer != -1) {

          this.value27 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 28 && userstore.state.draftanswers[i].answer_integer != -1) {

          this.value28 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 29 && userstore.state.draftanswers[i].answer_integer != -1) {

          this.value29 = String(userstore.state.draftanswers[i].answer_integer);
        }
        else if (userstore.state.draftanswers[i].question_id == 30 && userstore.state.draftanswers[i].answer_integer != -1) {

          this.value30 = String(userstore.state.draftanswers[i].answer_integer);
        }
      }
    }


    //    this.value1="2";

  },

}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
